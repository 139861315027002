
<div class="section-banner | banner" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch">
    <div fxFlex="100" class="fondo-banner">
      <img src="../../../../assets/img/banner_xl.png" fxHide.xs fxHide.sm fxHide.lg fxHide.md>
      <img src="../../../../assets/img/banner_lg.png" fxHide.xs fxHide.sm fxHide.xl fxHide.gt>
      <img src="../../../../assets/img/banner_sm.png" fxHide.xs fxHide.lg fxHide.md fxHide.xl fxHide.gt>
      <img src="../../../../assets/img/banner_xs.png" fxHide.sm fxHide.lg fxHide.md fxHide.xl fxHide.gt>
    </div>
    <div fxFlex="50" fxFlex.xs="95" fxFlex.sm="75" fxFlex.md="66" fxFlex.lg="66" class="content-banner" fxLayout="column" fxLayoutAlign="space-around center">
      <span class="banner-text">#Todaaccióncuenta</span>
    </div>
  </div>