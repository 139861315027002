<section>
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="carrousel" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch">
      <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="carousel" fxFlex="100">
        <ngu-tile *nguCarouselDef="let carousel">
          <div fxFlex="100" fxLayout="row wrap" class="banner" fxLayoutAlign="center stretch">
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_gd}}" fxHide.xs fxHide.sm fxHide.md
              fxHide.lg>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_ch}}" fxHide.xs fxHide.sm fxHide.md
              fxHide.xl>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_ch}}" fxHide.xs fxHide.sm fxHide.lg
              fxHide.xl>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_sm}}" fxHide.xs fxHide.md fxHide.lg
              fxHide.xl>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_xs}}" fxHide.sm fxHide.md fxHide.lg
              fxHide.xl>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="carousel.id == 0">
              <p fxFlex="50" fxFlex.lg="64" fxFlex.md="70" fxFlex.sm="85" fxFlex.xs="80" class="banner-title bold">
                ¡Porque tenemos el <span class="color-green bold">poder </span><br fxHide.xs>
                de <span class="color-green bold banner-frases">transformar!</span>
              </p>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="carousel.id == 1">
              <p fxFlex="70" fxFlex.lg="75" fxFlex.md="75" fxFlex.sm="85" fxFlex.xs="85" class="banner-title bold">Una
                pequeña <span class="color-green bold banner-frases2">acción</span> puede
                generar <span class="color-green bold banner-frases">grandes <span
                    class="color-white bold banner-frases">cambios.</span> </span></p>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="carousel.id == 2">
              <p fxFlex="90" fxFlex.lg="92" fxFlex.md="92" fxFlex.xs="80" class="banner-title bold">Movimiento <span
                  class="color-green bold">global</span> de seres
                <span class="color-green bold">comprometidos</span> consigo mismos, la sociedad
                y el planeta.
              </p>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="carousel.id == 3">
              <p fxFlex="55" fxFlex.lg="59" fxFlex.md="59" fxFlex.sm="85" fxFlex.xs="85" class="banner-title bold"><span
                  class="color-green bold">#SomosInfluenSer</span> porque
                toda <span class="color-green bold">acción</span> cuenta.</p>
            </div>
            <div class="content_banner" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <button class="button_more" (click)="openMailito()">Saber más</button>
            </div>
          </div>
        </ngu-tile>
        <ul class="myPoint" NguCarouselPoint>
          <li *ngFor="let i of myCarousel.pointNumbers" [class.active]="i==myCarousel.activePoint"
            (click)="myCarousel.moveTo(i)"></li>
        </ul>
        <button NguCarouselPrev class="leftRs" fxHide.xs>
          <img class="social__img" src="../../../../assets/icons/arrow_left.svg">
          <img class="social__img--hover" src="../../../../assets/icons/arrow_leftH.svg">
        </button>
        <button NguCarouselNext class="rightRs" fxHide.xs>
          <img class="social__img" src="../../../../assets/icons/arrow_right.svg">
          <img  class="social__img--hover" src="../../../../assets/icons/arrow_rightH.svg">
        </button>
      </ngu-carousel>
    </div>

  </div>