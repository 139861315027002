import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';

@Component({
  selector: 'app-influenser-natural',
  templateUrl: './influenser-natural.component.html',
  styleUrls: ['./influenser-natural.component.css']
})
export class InfluenserNaturalComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
    AOS.init({
      duration: 1000
    });
  }

  services(){
    this.router.navigate(["/services"]);
    this.sidenavToggle.emit();
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

}
