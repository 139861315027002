import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  public videos = [
    "https://www.youtube.com/watch?v=nwAYpLVyeFU", 
    "https://www.youtube.com/watch?v=PivWY9wn5ps",
    "https://www.youtube.com/watch?v=VOgFZfRVaww", 
    "https://www.youtube.com/watch?v=s3wNuru4U0I",
    "https://www.youtube.com/watch?v=BWf-eARnf6U", 
    "https://www.youtube.com/watch?v=gmfeNB1gFIc",
    "https://www.youtube.com/watch?v=GsaLyOuKLL8", 
    "https://www.youtube.com/watch?v=6UCuWxWiMaQ",
    "https://www.youtube.com/watch?v=0E0e6NqcT0M", 
    "https://www.youtube.com/watch?v=mC6IR1o7neY",
  ];

  constructor() { }

  ngOnInit(): void {
  }
  redesOpen(index) {
    window.open(this.videos[index], '_blank');
  }
}
