<div class="section_contact | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="content_banner" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <img class="banner" fxFlex="100" src="../../../assets/img/notfound_xl.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.lg>
        <img class="banner" fxFlex="100" src="../../../assets/img/notfound_lg.png" fxHide.xs fxHide.sm fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/notfound_lg.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/notfound_sm.png" fxHide.xs fxHide.md fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/notfound_xs.png" fxHide.sm fxHide.md fxHide.lg
            fxHide.xl>
    </div>
    <div class="title | color-white" fxLayout="row wrap" fxFlex="100"  fxLayoutAlign="start start">
        <p fxFlex="100" >¡UPS! ERROR 404</p>
    </div>
    <div class="text | color-white" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <p fxFlex="100">PÁGINA NO ENCONTRADA</p>
    </div>
    <div class="subtitle | color-white" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <p fxFlex="100">La página que estabas buscando no existe<br> o pudo haberse movido.</p>
    </div>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
        <button class="button_submit |  color-wine-ligth3 " type="submit"
            (click)="home()">Ir a Inicio</button>
    </div>
    
</div>