<div class="content" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="content_pillars background-green-ligth" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <p class="text_pillar color-blue" fxFlex.xs="70">En Influen<span class="bold900 boldxs">Ser</span> operamos con
            base en 3 pilares</p>
        <div class="weare__services color-blue" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center"
            fxLayoutAlign.xs="center start">
            <div class="" fxFlex="100" fxFlex.xs="80" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs
                fxHide.sm>
                <div class="weare__services--card social__card" fxFlex="33.3" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                    fxLayoutAlign="center center">
                    <img class="social__img" fxFlex="100" src="../../../../assets/img/every_action_counts_xl.png" alt="InfluenSer">
                    <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/every_action_counts_xlH.png" alt="InfluenSer">
                    <div class="weare__services--hover color-white" fxFlex="80" fxLayout="row wrap"
                        fxLayoutAlign="center end">
                        <p>Las decisiones que tomamos no solo nos afectan a nosotros, sino que tienen un impacto en los
                            demás y en el planeta.</p>
                    </div>
                </div>
                <div class="weare__services--card social__card" fxFlex="33.3" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                    fxLayoutAlign="center center">
                    <img class="social__img" fxFlex="100" src="../../../../assets/img/shared_value_xl.png" alt="InfluenSer">
                    <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/shared_value_xlH.png" alt="InfluenSer">
                    <div class="weare__services--hover color-white" fxFlex="80" fxLayout="row wrap"
                        fxLayoutAlign="center end">
                        <p>Al generar valor compartido generamos valor para nosotros mismos.</p>
                    </div>
                </div>
                <div class="weare__services--card social__card" fxFlex="33.3" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                    fxLayoutAlign="center center">
                    <img class="social__img" fxFlex="100" src="../../../../assets/img/continuous_improvement_xl.png" alt="InfluenSer">
                    <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/continuous_improvement_xlH.png" alt="InfluenSer">
                    <div class="weare__services--hover color-white" fxFlex="80" fxLayout="row wrap"
                        fxLayoutAlign="center end">
                        <p>Cada día podemos generar un mejor impacto.</p>
                    </div>
                </div>
                <div fxFlex="33.3" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                    <p class="weare__services--subtitle color-blue" fxLayoutAlign="center center">Toda acción
                        cuenta</p>
                </div>
                <div fxFlex="33.3" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                    <p class="weare__services--subtitle color-blue" fxLayoutAlign="center center">Valor
                        compartido</p>
                </div>
                <div fxFlex="33.3" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                    <p class="weare__services--subtitle color-blue" fxLayoutAlign="center center">Desarrollo
                        de Estrategias</p>
                </div>
            </div>
            <div class="weare__generation color-white" fxFlex="100" fxFlex.sm="80" fxLayout="row wrap"
                fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start" fxHide.md fxHide.gt fxHide.lg fxHide.xl>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
                    <ngu-carousel class="carrousel" #myCarousel [inputs]="carouselConfig" [dataSource]="generation">
                        <ngu-tile *nguCarouselDef="let item" fxLayout="clumn" fxLayoutAlign="center center">
                            <div class="weare__services--card" fxFlex="100" fxLayout="row wrap"
                                fxLayoutAlign="center center">
                                <img fxFlex="90" src="{{item.img}}" alt="InfluenSer" fxHide.sm fxHide.md fxHide.gt
                                fxHide.lg fxHide.xl>
                                <img fxFlex="100" src="{{item.imgsm}}" alt="InfluenSer" fxHide.xs fxHide.md fxHide.gt
                                fxHide.lg fxHide.xl>
                                <div class="weare__services--hover color-white" fxFlex="80" fxFlex.sm="73" fxLayout="row wrap"
                                    fxLayoutAlign="center end">
                                    <p> {{item.text}}</p>
                                </div>
                                <div fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
                                    <p class="weare__services--subtitle color-blue" fxLayoutAlign="center center">
                                        {{item.title}}</p>
                                </div>
                            </div>
                        </ngu-tile>
                        <ul class="myPoint" NguCarouselPoint>
                            <li *ngFor="let i of myCarousel.pointNumbers; let j = index"
                                [class.active]="i == myCarousel.activePoint" (click)="myCarousel.moveTo(i)"
                                [style.background]="'url(' + generation[j] + ')'"></li>
                        </ul>
                    </ngu-carousel>
                </div>
            </div>
            <div fxFlex="70" fxFlex.sm="60" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs >
                <button class="weare__services--button" fxFlex="25" fxFlex.sm="45"  fxFlex.md="35" fxLayout="row wrap"
                    fxLayoutAlign="center center" (click)="more()">Conoce más</button>
            </div>
            <div fxFlex="65" fxFlex.xs="85" fxFlex.sm="85" fxFlex.md="80" fxLayout="row wrap" fxLayoutAlign="center center">
                <p class="text_propuse">“El propósito de la compañía debe ser redefinido como la creación de valor
                    compartido, no sólo el
                    beneficio per se. Esto impulsará la próxima ola de innovación y crecimiento de la productividad en
                    la
                    economía mundial.”<br> <span class="bold">-Michael Porter</span></p>
            </div>
            <div fxFlex="70" fxFlex.xs="85" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.sm fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
                <button class="weare__services--button" fxFlex.xs="75" fxFlex.sm="45" fxLayout="row wrap"
                    fxLayoutAlign="center center" (click)="more()">Conoce más</button>
            </div>
        </div>

    </div>

</div>