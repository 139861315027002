<div [ngClass]="navbarfixed?'navbar2 background-blue shadow':'navbar background-active shadow'" fxLayout="row wrap"
  fxLayoutAlign="space-between stretch" fxHide.xs fxHide.sm fxLayoutAlign="center center">
  <div class="navbar__content" fxFlex="90" fxFlex.lg="80" fxLayout="row wrap">
    <div fxFlex="20" fxLayoutAlign="center center">
      <img  fxFlex.md="100"  src="../../../assets/icons/logo_influenser.svg" [routerLink]="['home']" (click)="scrollTop()" alt="InfluenSer">
    </div>
    <div class="logos" fxFlex="80" fxLayout="row" fxLayoutAlign="end center" fxHide.sm>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end center">
        <span class="navbar__link color-white" [routerLink]="['home']" [routerLinkActive]="['active_link']" (click)="scrollTop()">INICIO</span>
        <span class="navbar__link color-white" [routerLink]="['services']" [routerLinkActive]="['active_link']" (click)="scrollTop()">SOMOS INFLUEN<span class="bold900">SER</span></span>
        <span class="navbar__link color-white" [routerLink]="['projects']" [routerLinkActive]="['active_link']" (click)="scrollTop()">PROYECTOS</span>
        <!-- <span class="navbar__link color-white" [routerLink]="['contact']" [routerLinkActive]="['active_link']" (click)="scrollTop()">CONTACTO</span> -->
        <div class="container" fxLayoutAlign="center center" id="linkedin">
          <div class="card front">
            <a href="https://co.linkedin.com/company/influenser-natural"
              target="_blank">
              <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/redes_in.svg"
                alt="InfluenSer" />
            </a>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
            <a href="https://co.linkedin.com/company/influenser-natural"
              target="_blank">
              <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/redes_inB.svg"
                alt="InfluenSer" />
            </a>
          </div>
        </div>
        <div class="container" fxLayoutAlign="center center" id="instagram">
          <div class="card front">
            <a href="https://www.instagram.com/influensernatural/" target="_blank">
              <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/redes_insta.svg"
                alt="InfluenSer" />
            </a>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
            <a href="https://www.instagram.com/influensernatural/" target="_blank">
              <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/redes_instaB.svg"
                alt="InfluenSer" />
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<mat-toolbar [ngClass]="navbarfixed?'navbar2 background-blue shadow':'navbar background-active shadow'" fxFlex="100"
  fxLayout="row" fxLayoutAlign="center stretch" fxHide.gt-sm>
  <div class="nav_mov" fxFlex="100" fxFlex.xs="95" fxFlex.sm="90" fxLayoutAlign="space-between center">
    <div class="marginT" fxFlex="100"  fxFlex.sm="95" fxLayoutAlign="center center" fxLayoutAlign.xs="start center">
      <img [routerLink]="['home']" (click)=" scrollTop()" fxFlex.xs="60" fxFlex.sm="35" src="../../../assets/icons/logo_influenser.svg" alt="InfluenSer"
        fxLayoutAlign="center center" fxLayoutAlign.xs="start center">
    </div>
    <div fxFlex="13" fxFlex.sm="6" fxLayoutAlign="end center">
      <button class="button_menu" fxFlex="100" (click)="onToggleSidenav()"
        fxLayoutAlign="center center">
        <img fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="70" fxFlex.lg="55"
          src="../../../assets/img/hamburguer2.png" alt="InfluenSer" />

      </button>
    </div>
  </div>
</mat-toolbar>
<div id="inicio"></div>