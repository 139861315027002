import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorComponent } from '../error/error.component';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  public message: any = '';
  public title: any = '';

  constructor(
    public dialogRef: MatDialogRef<ErrorComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.title = data.title;
  }

  ngOnInit(): void {
  }

  aceptar() {
    this.dialogRef.close();
  }

}
