import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { GeneralService } from "src/app/services/general.service";
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
@Component({
  selector: 'app-we-are-influenser',
  templateUrl: './we-are-influenser.component.html',
  styleUrls: ['./we-are-influenser.component.css']
})
export class WeAreInfluenserComponent implements OnInit {
  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
  public carouselConfig: NguCarouselConfig;
  public instaURL: Array<any> = [];
  public instaURLMedia: Array<any> = [];
  public images: Array<any> = [];
  private accessToken:string="IGQVJWZA3pFNUhLVnV0cTk2WHlUY0lqNndXQllBQXlRT3hMVHFxa1puYnpOQkJDbHZAVaUpvdUNxVktZAR1VWLTMydkpMSmtXZATNlN3ZAuenNMSmEzWi1wV19aS2RPVENVSG1Wc0JUOHZAmOVV0OHZATOFJUagZDZD";
  public team: any = [
    {
      img:"/assets/img/somos__Esteban.png",
      imgH:"/assets/img/somos__EstebanH.png",
      name:"Esteban Giraldo",
      charge:"Director Cofundador InfluenSer Natural",
      grade:"Administrador en Negocios Internacionales.",
      country:"Colombia",
      insta:"https://www.instagram.com/estebangiraldo9/",
      linkedin:"https://www.linkedin.com/in/egiral37/"
    },
    {
      img:"/assets/img/somos__Shelley.png",
      imgH:"/assets/img/somos__ShelleyH.png",
      name:"Shelley",
      charge:"Líder Internacional Estratega y visión global.",
      grade:"Administradora en Negocios Internacionales.",
      country:"Nueva Zelanda",
      insta:"https://instagram.com/shells_medhurst/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Benito.png",
      imgH:"/assets/img/somos__BenitoH.png",
      name:"Benito Mendoza",
      charge:"Líder de Tecnología Cofundador InfluenSer Natural",
      grade:"Ingeniero Telemático.",
      country:"México",
      insta:"",
      linkedin:"https://www.linkedin.com/in/benito-mendoza-cruz-2b5a77106/"
    },
    {
      img:"/assets/img/somos__Julia.png",
      imgH:"/assets/img/somos__JuliaH.png",
      name:"Julia Restrepo",
      charge:"Responsable de Marco Teórico Investigadora en cambio comportamental.",
      grade:"Ingeniera de diseño y psicóloga.",
      country:"Colombia",
      insta:"https://instagram.com/juliasolito/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Guty.png",
      imgH:"/assets/img/somos__GutyH.png",
      name:'Luis Gutiérrez “Guty”',
      charge:"Líder de Proyectos Especialista en ventas minoristas.",
      grade:"Productor musical.",
      country:"Colombia",
      insta:"https://instagram.com/guty668/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Andrea.png",
      imgH:"/assets/img/somos__AndreaH.png",
      name:"Andrea González",
      charge:"Responsable de Comunicaciones Customer experience y marketing digital.",
      grade:"Comunicadora social.",
      country:"Colombia",
      insta:"https://instagram.com/andreagonzao/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Candelo.png",
      imgH:"/assets/img/somos__CandeloH.png",
      name:"Candelo",
      charge:"Líder Comercial Líder social, emprendedor y conferencista.",
      grade:"Productor musical.",
      country:"Colombia",
      insta:"https://instagram.com/candelo_oficial/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Adriana.png",
      imgH:"/assets/img/somos__AdrianaH.png",
      name:"Adriana Fuam",
      charge:"Responsable de proyectos Mentora de liderazgo consciente.",
      grade:"Admin en negocios internacionales.",
      country:"Colombia",
      insta:"https://instagram.com/adrianafuam/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Juanpa.png",
      imgH:"/assets/img/somos__JuanpaH.png",
      name:"Juan Pa",
      charge:"Líder de Movimiento Emprendedor y especialista en desarrollo de proyectos.",
      grade:"Comunicador social.",
      country:"Colombia",
      insta:"https://instagram.com/juanp8a/",
      linkedin:""
    },
  ];
  public esteban : any = {
    img:"/assets/img/somos__Esteban.png",
    imgH:"/assets/img/somos__EstebanH.png",
    name:"Esteban Giraldo",
    charge:"Director Cofundador InfluenSer Natural",
    grade:"Administrador en Negocios Internacionales.",
    country:"Colombia",
    insta:"https://www.instagram.com/estebangiraldo9/",
    linkedin:"https://www.linkedin.com/in/egiral37/"
  }
  public teamTablet: any = [
    {
      img:"/assets/img/somos__Shelley.png",
      imgH:"/assets/img/somos__ShelleyH.png",
      name:"Shelley",
      charge:"Líder Internacional Estratega y visión global.",
      grade:"Administradora en Negocios Internacionales.",
      country:"Nueva Zelanda",
      insta:"https://instagram.com/shells_medhurst/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Benito.png",
      imgH:"/assets/img/somos__BenitoH.png",
      name:"Benito Mendoza",
      charge:"Líder de Tecnología Cofundador InfluenSer Natural",
      grade:"Ingeniero Telemático.",
      country:"México",
      insta:"",
      linkedin:"https://www.linkedin.com/in/benito-mendoza-cruz-2b5a77106/"
    },
    {
      img:"/assets/img/somos__Julia.png",
      imgH:"/assets/img/somos__JuliaH.png",
      name:"Julia Restrepo",
      charge:"Responsable de Marco Teórico Investigadora en cambio comportamental.",
      grade:"Ingeniera de diseño y psicóloga.",
      country:"Colombia",
      insta:"https://instagram.com/juliasolito/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Guty.png",
      imgH:"/assets/img/somos__GutyH.png",
      name:'Luis Gutiérrez “Guty”',
      charge:"Líder de Proyectos Especialista en ventas minoristas.",
      grade:"Productor musical.",
      country:"Colombia",
      insta:"https://instagram.com/guty668/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Andrea.png",
      imgH:"/assets/img/somos__AndreaH.png",
      name:"Andrea González",
      charge:"Responsable de Comunicaciones Customer experience y marketing digital.",
      grade:"Comunicadora social.",
      country:"Colombia",
      insta:"https://instagram.com/andreagonzao/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Candelo.png",
      imgH:"/assets/img/somos__CandeloH.png",
      name:"Candelo",
      charge:"Líder Comercial Líder social, emprendedor y conferencista.",
      grade:"Productor musical.",
      country:"Colombia",
      insta:"https://instagram.com/candelo_oficial/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Adriana.png",
      imgH:"/assets/img/somos__AdrianaH.png",
      name:"Adriana Fuam",
      charge:"Responsable de proyectos Mentora de liderazgo consciente.",
      grade:"Admin en negocios internacionales.",
      country:"Colombia",
      insta:"https://instagram.com/adrianafuam/",
      linkedin:""
    },
    {
      img:"/assets/img/somos__Juanpa.png",
      imgH:"/assets/img/somos__JuanpaH.png",
      name:"Juan Pa",
      charge:"Líder de Movimiento Emprendedor y especialista en desarrollo de proyectos.",
      grade:"Comunicador social.",
      country:"Colombia",
      insta:"https://instagram.com/juanp8a/",
      linkedin:""
    },
  ];
  public generation: any = [
    {
      img:"/assets/img/somos__articulacion.svg",
      title:"Articulación",
      text:"Entendimiento y alineación de todo el ecosistema y de todas las partes que lo componen."
    },
    {
      img:"/assets/img/somos__metodologia.svg",
      title:"Metodología Emergente",
      text:"Rompiendo paradigmas para la obtención de nuevos resultados no buscados."
    },
    {
      img:"/assets/img/somos__interdisciplinariedad.svg",
      title:"Interdisciplinariedad",
      text:"Múltiples disciplinas, contextos, generaciones y experiencia al servicio de nuestros proyectos."
    }
  ];
  constructor(private sanitizer: DomSanitizer,private service:GeneralService) {
    this.getToken();
  }
  ngOnInit(): void {
    this.carouselConfig = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      load: 1,
      interval: { timing: 4000, initialDelay: 1000 },
      loop: true,
      touch: true,
      velocity: 0.2,
      point: {
        visible: true,
        hideOnSingleSlide: true,
      },
    };
  }
  getToken(){
    this.service.obtenerToken().subscribe((res)=>{
      console.log(res.modification_date);
      this.accessToken = res.token;
      console.log("TokenAccess",this.accessToken);
      this.getURL();
    },(error)=>{
      console.log(error);
      this.getURL();
    });
  }
  getURL(){
    this.service.obtenerURL(this.accessToken).subscribe((res)=>{     
      for(let i: number = 0; i < 10; i++){
        this.instaURL[i] = {
          id: res.data[i].id,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(res.data[i].permalink+'embed'),
          permalink: res.data[i].permalink
        }
        this.getURLMedia(res.data[i].id); 
      }
    },(error)=>{
      console.log(error);
    });
  }
  goHashtag(){
    window.open('https://www.instagram.com/explore/tags/somosinfluenser/', "_blank");
  }
  goInsta(){
    window.open('https://www.instagram.com/influensernatural/', "_blank");
  }
  async getURLMedia(id){
    this.service.obtenerURLMedia(id,this.accessToken).subscribe((res)=>{
      this.instaURLMedia.push(res);
    }, (error) =>{
      console.log(error);
    });
  }
  goPublish(post){
    console.log(post);
    for(let i = 0; i<this.instaURL.length;i++){
      if(post.id == this.instaURL[i].id){
        window.open(this.instaURL[i].permalink, "_blank");
        break;
      }
    }
  }
  goMedia(red,index){
    if(red == 'instagram'){
      console.log("Instagram:",index.insta);
      window.open(index.insta, "_blank");
    }else{
      console.log("Linkedin",index.linkedin);
      window.open(index.linkedin, "_blank");
    }
  }
  openMailito() {
    window.open("mailto:info@influensernatural.com?Subject=Información%20InfluenSer")
  }
}
