import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/components/message/error/error.component';
import { SuccessComponent } from 'src/app/components/message/success/success.component';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @ViewChild('fo') myForm;
  public form: FormGroup;
  public submitted: boolean = false;
  public showLoad: boolean = false;

  constructor(private servicio: GeneralService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.submitted = false;
    this.doForm();

  }

  doForm(): void {
    this.form = new FormGroup({
      full_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });
  }

  submit() {
     this.submitted = true;
     console.log(this.form);
     if (this.form.invalid) {
       return;
     }
     const dataJson = {
         "nombre":  this.form.value.full_name,
         "correo":  this.form.value.email,
         "telefono":  this.form.value.phone,
         "empresa":  this.form.value.company,
         "descripcion":  this.form.value.message,
     }
  
     this.showLoad = true;
      this.servicio.sendContacto(dataJson).subscribe((res) => {
      this.showLoad = false;
      let dialogSucces = this.dialog.open(SuccessComponent, {
       width: '700px',
       height: '300px',
       data: {
         title: '¡Tu mensaje se envió correctamente, gracias por querer ser parte de InfluenSer!',
       },
     disableClose: true,

     });
      dialogSucces.afterClosed().subscribe(res => {
        this.dialog.closeAll();
        this.myForm.resetForm();
        this.form.reset();
        this.form.get('full_name').setValue('');
        this.form.get('email').setValue('');
        this.form.get('phone').setValue('');
        this.form.get('company').setValue('');
        this.form.get('message').setValue('');
      });
      this.submitted = false;
    }, (error) => {
      this.showLoad = false;

      let dialogError = this.dialog.open(ErrorComponent, {
        width: '605px',
        data: {
          title: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!',
        },
        disableClose: true

      });
      dialogError.afterClosed().subscribe(res => {
        this.dialog.closeAll();
        this.myForm.resetForm();
        this.form.reset();
        this.form.get('full_name').setValue('');
        this.form.get('email').setValue('');
        this.form.get('phone').setValue('');
        this.form.get('message').setValue('');
        this.form.get('company').setValue('');
      });
      });
  }
  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  openMailito(){
    window.open("mailto:info@influensernatural.com?Subject=Información%20InfluenSer")
  }

}
