<div class="content_gallery background-green-ligth" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly center"
    fxLayoutAlign.xs="space-evenly start">
    <div fxFlex="19" fxFlex.xs="47" fxFlex.sm="47" fxLayout="row wrap" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.xs="space-evenly start">
        <div class="social__card" fxFlex="100" (click)="redesOpen(0)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img1_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img1H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img1_lg.png" fxHide.xs fxHide.sm fxHide.xl
                fxHide.gt>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img1H_lg.png" fxHide.xs fxHide.sm
                fxHide.xl fxHide.gt>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img1_xl.png" fxHide.xs fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img1H_xl.png" fxHide.xs fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img1_xs.png" fxHide.sm fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
        </div>

        <div class="marginT  social__card" fxFlex="100" (click)="redesOpen(1)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img2_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img2H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img2_lg.png" fxHide.xs fxHide.sm fxHide.xl
                fxHide.gt>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img2H_lg.png" fxHide.xs fxHide.sm
                fxHide.xl fxHide.gt>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img2_xl.png" fxHide.xs fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img2H_xl.png" fxHide.xs fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img2_xs.png" fxHide.sm fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
        </div>
    </div>
    <div fxFlex="19" fxFlex.xs="47" fxFlex.sm="47" fxLayout="row wrap" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.xs="space-evenly start">
        <div class="social__card" fxFlex="100" (click)="redesOpen(2)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img3_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img3H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img3_lg.png" fxHide.xs fxHide.sm fxHide.xl
                fxHide.gt>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img3H_lg.png" fxHide.xs fxHide.sm
                fxHide.xl fxHide.gt>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img3_xl.png" fxHide.xs fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img3H_xl.png" fxHide.xs fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img3_xs.png" fxHide.sm fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
        </div>
        <div fxFlex="100" class="marginT social__card" (click)="redesOpen(3)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img4_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img4H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img " fxFlex="100" src="../../../../assets/img/img4_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img--hover " fxFlex="100" src="../../../../assets/img/img4H_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img4_xl.png" fxHide.xs fxHide.gt fxHide.md
                fxHide.lg fxHide.xl>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img4H_xl.png" fxHide.xs fxHide.gt
                fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img img_lg" fxFlex="100" src="../../../../assets/img/img4_xs.png" fxHide.sm fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
        </div>
    </div>
    <div fxFlex="19" fxFlex.xs="47" fxFlex.sm="47" fxLayout="row wrap" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.xs="space-evenly start">
        <div class="social__card contet_img5" fxFlex="100" (click)="redesOpen(4)">
            <p class="somosin color-blue-green" fxLayoutAlign="center center" fxHide.xs>#SomosInfluen<span
                    class="color-blue bold900">Ser</span></p>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img5_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img5H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img " fxFlex="100" src="../../../../assets/img/img5_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img--hover " fxFlex="100" src="../../../../assets/img/img5H_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img margintT" fxFlex="100" src="../../../../assets/img/img5_xl.png" fxHide.xs fxHide.gt
                fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img--hover margintT" fxFlex="100" src="../../../../assets/img/img5H_xl.png" fxHide.xs
                fxHide.gt fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img margintT" fxFlex="100" src="../../../../assets/img/img5_xs.png" fxHide.sm fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
        </div>
        <div fxFlex="100" class="marginT social__card" (click)="redesOpen(5)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img6_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img6H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img " fxFlex="100" src="../../../../assets/img/img6_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img--hover " fxFlex="100" src="../../../../assets/img/img6H_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img6_xl.png" fxHide.xs fxHide.gt fxHide.md
                fxHide.lg fxHide.xl>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img6H_xl.png" fxHide.xs fxHide.gt
                fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img6_xs.png" fxHide.sm fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
        </div>
    </div>
    <div fxFlex="19" fxFlex.xs="47" fxFlex.sm="47" fxLayout="row wrap" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.xs="space-evenly start">
        <div class="social__card" fxFlex="100" (click)="redesOpen(6)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img7_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img7H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img " fxFlex="100" src="../../../../assets/img/img7_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img--hover " fxFlex="100" src="../../../../assets/img/img7H_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img7_xl.png" fxHide.xs fxHide.gt fxHide.md
                fxHide.lg fxHide.xl>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img7H_xl.png" fxHide.xs fxHide.gt
                fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img margintT" fxFlex="100" src="../../../../assets/img/img7_xs.png" fxHide.sm fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
        </div>
        <div fxFlex="100" class="marginT social__card" (click)="redesOpen(7)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img8_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img8H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img " fxFlex="100" src="../../../../assets/img/img8_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img--hover " fxFlex="100" src="../../../../assets/img/img8H_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img8_xl.png" fxHide.xs fxHide.gt fxHide.md
                fxHide.lg fxHide.xl>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img8H_xl.png" fxHide.xs fxHide.gt
                fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img8_xs.png" fxHide.sm fxHide.md fxHide.gt
                fxHide.lg fxHide.xl>
        </div>
    </div>
    <div fxFlex="19" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="space-evenly center"
        fxLayoutAlign.xs="space-evenly start">
        <div class="social__card" fxFlex="100" fxFlex.xs="48" fxFlex.sm="48" (click)="redesOpen(8)">
            <img class="social__img" fxFlex="100" src="../../../../assets/img/img9_xl.png" fxHide.xs fxHide.sm fxHide.md
                fxHide.lg>
            <img class="social__img--hover" fxFlex="100" src="../../../../assets/img/img9H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img " fxFlex="100" src="../../../../assets/img/img9_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img--hover " fxFlex="100" src="../../../../assets/img/img9H_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img marginT" fxFlex="100" src="../../../../assets/img/img9_xl.png" fxHide.xs fxHide.gt
                fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img--hover marginT" fxFlex="100" src="../../../../assets/img/img9H_xl.png" fxHide.xs
                fxHide.gt fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img margintT" fxFlex="100" src="../../../../assets/img/img9_xs.png" fxHide.sm fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
        </div>
        <div fxFlex="100" fxFlex.xs="48" fxFlex.sm="48" class=" social__card" (click)="redesOpen(9)">
            <img class="social__img marginT" fxFlex="100" src="../../../../assets/img/img10_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img--hover marginT" fxFlex="100" src="../../../../assets/img/img10H_xl.png" fxHide.xs fxHide.sm
                fxHide.md fxHide.lg>
            <img class="social__img marginT" fxFlex="100" src="../../../../assets/img/img10_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img--hover marginT" fxFlex="100" src="../../../../assets/img/img10H_lg.png" fxHide.xs fxHide.sm
                fxHide.gt fxHide.xl>
            <img class="social__img mrginsm" fxFlex="100" src="../../../../assets/img/img10_xl.png" fxHide.xs fxHide.gt
                fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img--hover mrginsm" fxFlex="100" src="../../../../assets/img/img10H_xl.png" fxHide.xs
                fxHide.gt fxHide.md fxHide.lg fxHide.xl>
            <img class="social__img marginTT" fxFlex="100" src="../../../../assets/img/img10_xs.png" fxHide.sm fxHide.md
                fxHide.gt fxHide.lg fxHide.xl>
        </div>
    </div>
</div>