<div class="background-green-ligth project__container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="bold900 project__banner color-white" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <p fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">#Toda<span
                class="color-green">acción</span>cuenta</p>
    </div>
    <p class="project--title color-blue-green" fxFlex="100" fxLayoutAlign="center center">Proyectos<span
            class="color-blue">&nbsp;InfluenSer</span></p>
    <div fxFlex="85" fxFlex.sm="80" fxFlex.md="100" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <!-- restructura -->
        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row wrap">
            <div class="content_second" fxFlex="90" fxLayoutAlign="center start" fxLayout="row wrap">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row wrap">
                    <img fxFlex="100" src="/assets/icons/line_ball.svg" fxHide.xs fxHide.sm fxHide.md
                        fxHide.lg>
                    <img fxFlex="100" src="/assets/icons/line_ball_lg.svg" fxHide.xs fxHide.sm fxHide.xl
                        fxHide.gt>
                    <img fxFlex="100" src="/assets/icons/line_ball_sm.svg" fxHide.xs fxHide.md fxHide.gt fxHide.lg
                        fxHide.xl>
                    <img fxFlex="100" src="/assets/icons/line_ball_xs.svg" fxHide.sm fxHide.md fxHide.gt fxHide.lg
                        fxHide.xl>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="content_text" fxLayout="row wrap">
                    <span fxFlex="100" class="project__second--title color-blue">SEGUNDAS OPORTUNIDADES</span>
                    <span fxFlex="100" class="project__second--subtitle color-blue">"Gestando nuestro propio
                        desarrollo"</span>
                    <p class="text_second" fxFlex="85" fxFlex.sm="100" fxFlex.xs="100">
                        Con apoyo de la Corporación Camposanto,
                        incentivamos que los habitantes de la Comuna 8 de
                        Medellín cambien su reciclaje por puntos que luego
                        podrán utilizar para obtener descuentos y beneficios
                        que disminuyen sus gastos y mejoran su
                        calidad de vida y la de su comunidad.
                    </p>
                    <p class="text_second" fxFlex="85" fxFlex.sm="100" fxFlex.xs="100">
                        Creamos una línea directa entre las personas y las
                        empresas para fortalecer la confianza empresarial y
                        dejar de lado la depencia estatal.
                    </p>
                    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutAlign.md="start end">
                        <span class="pointer color-blue-green" fxFlex.md="40" fxLayoutAlign="start end" (click)="goToLink(3)">
                            <b class="text_links">Aliado:</b>
                            <img fxFlex="100" fxFlex.xs="60" fxFlex.md="50"  src="/assets/img/project__ciclo.png" alt="InfluenSer">
                        </span>
                        <span class="pointer color-blue-green" fxFlex.md="40" fxLayoutAlign="start end" (click)="goToLink(4)">
                            <b class="text_links">Apoya:</b>
                            <img fxFlex="100" fxFlex.xs="80"  src="/assets/img/project__apoya.png" alt="InfluenSer">
                        </span>
                    </div>
                    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
                        <button fxFlex="25" fxFlex.md="40" fxFlex.sm="35" fxFlex.xs="75" class="project__button"
                            (click)="openMailito()">Conoce
                            más</button>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="content_img" fxLayout="row wrap" fxLayoutAlign.md="center center">
                    <img fxFlex="100" fxFlex.md="80" fxFlex.sm="85" fxFlex.xs="85" src="/assets/img/project__secondchances.png" alt="InfluenSer">
                    <img class="project__secondchances--play" fxFlex.md="30" fxFlex.xs="20" src="/assets/img/project__play.png" alt="influenSer"
                        (click)="goToLink(0)">
                </div>

            </div>
            <div class="content_second" fxFlex="90" fxLayoutAlign="center center" fxLayoutAlign.sm="center start" fxLayoutAlign.xs="center start"  fxLayout="row wrap">
                <div class="pr-2" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="content_text2" fxLayout="row wrap">
                    <span fxFlex="100" class="project__second--title textend color-blue">EL RENACER DE LA CIÉNEGA</span>
                    <span fxFlex="100" class="project__second--subtitle textend color-blue">"Articulación integral
                        autosostenible"</span>
                    <p class="text_second textend" fxFlex="100">
                        Proyecto de articulación integrada autosostenible para
                        la conservación de la Ciénaga de Ayapel, Córdoba y
                        traer más y mejor desarrollo sus habitantes, mientras
                        se fortalece el vínculo socio-ambiental-económico
                        entre los actores del territorio.
                    </p>
                    <p class="text_second textend" fxFlex="100">
                        Apropiación del territorio, vinculación a la escuela
                        superior, gestión del tejido social son algunos de los
                        objetivos que plantea el proyecto.
                    </p>
                    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end">
                        <span class="pointer textend color-blue-green" fxLayoutAlign="end end" (click)="goToLink(5)">
                            <b class="">Aliado:</b>
                            <img fxFlex="100" fxFlex.xs="60" src="/assets/img/project__ayapel.png" alt="InfluenSer">
                        </span>
                        <!-- <span class="pointer color-blue-green" fxLayoutAlign="start end" (click)="goToLink(4)">
                            <b class="text_links">Apoya:</b>
                            <img fxFlex="100" src="/assets/img/project__apoya.png" alt="InfluenSer">
                        </span> -->
                    </div>
                    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end">
                        <button fxFlex="25" fxFlex.md="40" fxFlex.sm="35" fxFlex.xs="75" class="project__button"
                            (click)="openMailito()">Conoce
                            más</button>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="content_img2" fxLayout="row wrap" fxLayoutAlign.md="center center">
                    <img fxFlex="100" fxFlex.md="80" fxFlex.xs="85"  src="/assets/img/project__reborn.png" alt="InfluenSer">
                    <img class="project_play2" fxFlex.md="30" fxFlex.xs="20" src="/assets/img/project__play.png" alt="influenSer"
                        (click)="goToLink(0)">
                </div>
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row wrap">
                    <img fxFlex="100" src="/assets/icons/line_ball2.svg" fxHide.xs fxHide.sm fxHide.md
                        fxHide.lg>
                    <img fxFlex="100" src="/assets/icons/line_ball2_lg.svg" fxHide.xs fxHide.sm fxHide.xl
                        fxHide.gt>
                    <img fxFlex="100" src="/assets/icons/line_ball2_sm.svg" fxHide.xs fxHide.md fxHide.gt fxHide.lg
                        fxHide.xl>
                    <img fxFlex="100" src="/assets/icons/line_ball2_xs.svg" fxHide.sm fxHide.md fxHide.gt fxHide.lg
                        fxHide.xl>
                </div>
            </div>
        </div>
        <!-- <div class="project__second" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
             
                <div class="content_text" fxFlex="50" fxFlex.xl="40" fxFlex.sm="100" fxFlex.xs="70" fxLayout="row wrap"
                    fxLayoutAlign="center center" data-aos="fade-right" data-aos-offset="100"
                    data-aos-easing="ease-in-sine">
                    <span fxFlex="100" class="project__second--title color-blue">SEGUNDAS OPORTUNIDADES</span>
                    <span fxFlex="100" class="project__second--subtitle color-blue">"Gestando nuestro propio
                        desarrollo"</span>
                    <p>
                        Con apoyo de la Corporación Camposanto,
                        incentivamos que los habitantes de la Comuna 8 de
                        Medellín cambien su reciclaje por puntos que luego
                        podrán utilizar para obtener descuentos y beneficios
                        que disminuyen sus gastos y mejoran su
                        calidad de vida y la de su comunidad.
                    </p>
                    <p>
                        Creamos una línea directa entre las personas y las
                        empresas para fortalecer la confianza empresarial y
                        dejar de lado la depencia estatal.
                    </p>
                    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between end">
                        <span class="pointer color-blue-green" fxFlex.sm="40" fxLayoutAlign="start center"
                            (click)="goToLink(3)">
                            <b>Aliado:</b>
                            <img fxFlex="40" src="/assets/img/project__ciclo.png" alt="InfluenSer">
                        </span>
                        <span class="pointer color-blue-green" fxFlex.sm="40" fxLayoutAlign="start center"
                            (click)="goToLink(4)">
                            <b>Apoya:</b>
                            <img fxFlex="70" src="/assets/img/project__apoya.png" alt="InfluenSer">
                        </span>
                    </div>
                    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
                        <button fxFlex="25" fxFlex.md="40" fxFlex.sm="35" fxFlex.xs="75" class="project__button">Conoce
                            más</button>
                    </div>
                </div>
                <div class="project__secondchances--img" fxFlex="50" fxFlex.sm="100" fxFlex.xs="70" fxLayout="row wrap"
                    fxLayoutAlign="center center" fxLayoutAlign.xs="end center" data-aos="fade-left"
                    data-aos-offset="100" data-aos-easing="ease-in-sine">
                    <img fxFlex="60" fxFlex.md="80" fxFlex.xs="90" src="/assets/img/project__secondchances.png"
                        alt="InfluenSer">
                    <img class="project__secondchances--play" fxFlex.xs="20" src="/assets/img/project__play.png"
                        alt="influenSer" (click)="goToLink(0)">
                </div>

            </div>

        </div>
        <div class="project__reborn" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
            <div class="project__secondchances--img" fxFlex="50" fxLayout="row wrap" fxLayoutAlign="center center"
                fxHide.xs fxHide.sm data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <img fxFlex="60" fxFlex.md="80" src="/assets/img/project__reborn.png" alt="InfluenSer">
                <img class="project__reborn--play" src="/assets/img/project__play.png" alt="influenSer"
                    (click)="goToLink(1)">
            </div>
            <div class="content_text2" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxFlex.xl="45" fxLayout="row wrap"
                fxLayoutAlign="end center" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <span fxFlex="100" class="project__second--title color-blue">EL RENACER DE LA CIÉNEGA</span>
                <span fxFlex="100" class="project__second--subtitle color-blue">"Articulación integral
                    autosostenible"</span>
                <p>
                    Proyecto de articulación integrada autosostenible para
                    la conservación de la Ciénaga de Ayapel, Córdoba y
                    traer más y mejor desarrollo sus habitantes, mientras
                    se fortalece el vínculo socio-ambiental-económico
                    entre los actores del territorio.
                </p>
                <p>
                    Apropiación del territorio, vinculación a la escuela
                    superior, gestión del tejido social son algunos de los
                    objetivos que plantea el proyecto.
                </p>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end start">
                    <span class="color-blue-green" fxLayoutAlign="end center">
                        <b>Aliado:</b>
                        <img fxFlex="50" src="/assets/img/project__ayapel.png" alt="InfluenSer">
                    </span>
                </div>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end start">
                    <button fxFlex="25" fxFlex.md="40" fxFlex.sm="35" fxFlex.xs="75" class="project__button" (click)="openMailito()">Conoce
                        más</button>
                </div>
            </div>
            <div class="project__secondchances--img" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap"
                fxLayoutAlign="center center" fxLayoutAlign.xs="start center" fxHide.gt-sm>
                <img fxFlex="60" fxFlex.md="80" fxFlex.md="80" fxFlex.xs="90" src="/assets/img/project__reborn.png"
                    alt="InfluenSer">
                <img class="project__reborn--play" fxFlex.xs="20" src="/assets/img/project__play.png" alt="influenSer"
                    (click)="goToLink(1)">
            </div>
        </div> -->
    </div>
    <div class="project__interactive color-white" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="70" fxFlex.xl="70" fxFlex.sm="70" fxFlex.md="80" fxFlex.xs="90" fxLayout="row wrap"
            fxLayoutAlign="center center">
            <span class="project__interactive--title" fxFlex="100" fxLayoutAlign="center center">Talleres <span
                    class="color-green">&nbsp;Interactivos</span></span>
            <div class="project__interactive--subtitle" fxFlex="100" fxLayout="row wrap"
                fxLayoutAlign="space-between start">
                <div class="mt-50" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center"
                    fxLayoutAlign.xl="start center" data-aos="fade-right" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <span class="project__second--title color-green2" fxFlex="100" fxFlex.xs="90">LIBERTAD ECONÓMICA CON
                        PROPÓSITO</span>
                    <p fxFlex.xl="80">
                        Aquí vemos a “Candelo” dictando una charla en la
                        empresa Cortineros Líder, donde generó un espacio
                        de reflexión y motivación, con el fin de entender el rol tan
                        importante que tenemos todos como
                        colombianos (desde los individuos hasta las
                        organizaciones) para la transformación sociocultural
                        de nuestro país.
                    </p>
                    <p fxFlex.xl="80">
                        Los colaboradores de esta bonita empresa tuvieron la
                        oportunidad de conocer acerca de la historia de
                        Cande y como las experiencias en su vida lo han llevado a
                        ser el líder que es y poder impactar la vida de
                        más de 5,000 jóvenes en la comuna 8 de Medellín, a
                        través del rap.
                    </p>
                </div>
                <div class="mt-30" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center"
                    data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img fxFlex="70" fxFlex.xs="100" src="/assets/img/project__liberty.png" alt="InfluenSer">
                </div>
            </div>
            <div class="project__interactive--subtitle" fxFlex="100" fxLayout="row wrap"
                fxLayoutAlign="space-between start">
                <div fxFlex="50" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs fxHide.sm
                    data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img fxFlex="60" fxFlex.xl="70" fxFlex.xs="100" src="/assets/img/project__capitalism.png"
                        alt="InfluenSer">
                </div>
                <div class="mt-50" fxFlex="50" fxFlex.sm="100" fxFlex.xl="45" fxFlex.xs="100" fxLayout="row wrap"
                    fxLayoutAlign="end center" data-aos="fade-left" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <span class="project__second--title color-green2" fxFlex="100">CAPITALISMO CONSCIENTE</span>
                    <p>
                        Experto invitado en participar de “Cocinando Estrategias”
                        una iniciativa Del Departamento Institucional y
                        Comunicaciones de la USB Cali que busca agregarle valor
                        al tejido empresarial de la ciudad-región a través de
                        temáticas de la mano de expertos, posicionando su
                        formación posgradual.
                    </p>
                    <p>
                        En este encuentro estuvimos “Cocinando” junto al equipo
                        de la Cámara de Comercio de Cali con quienes pudimos
                        conversar del Capitalismo Consciente y de los retos que
                        afrontan las mipymes a la hora de querer sumarse a este
                        movimiento.
                    </p>
                </div>
                <div class="mt-30" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center"
                    fxHide.gt-sm data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img fxFlex="60" fxFlex.xs="100" src="/assets/img/project__capitalism.png" alt="InfluenSer">
                </div>
            </div>
            <div class="project__interactive--subtitle" fxFlex="100" fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <div fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="start center"
                    data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <span class="project__second--title color-green2" fxFlex="100">CONTRASTA – DOS</span>
                    <p fxFlex="85" fxFlex.sm="100" fxFlex.xl="75" fxLayoutAlign="start start">
                        Es un proyecto transmedia que quiere tumbar prejuicios y
                        conocer todo lo que hay detrás del mundo artístico. Un
                        espacio personal e íntimo donde un artista y un
                        empresario del gremio tratarán de conocer la realidad del
                        otro, para descubrir nuevas verdades, conectarse con el
                        otro e intentar ponerse en sus zapatos. Buscamos
                        entretener al público por medio de esta experiencia, que
                        permita descubrir a estos dos tipos de personas, que al
                        final siempre están conectadas por el arte, pero que la
                        mayoría de las veces ni siquiera se conocen.
                    </p>
                </div>
                <div class="mt-30 project__secondchances--img" fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                    fxLayoutAlign="space-around center" data-aos="fade-left" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <img class="project__interactive--woman" fxFlex="45" src="/assets/img/project__woman.png"
                        alt="InfluenSer">
                    <img fxFlex="45" src="/assets/img/project__men.png" alt="InfluenSer">
                    <img class="project__interactive--play" fxFlex="15" src="/assets/img/project__play.png"
                        alt="influenSer" (click)="goToLink(2)">
                </div>
            </div>
        </div>
    </div>
    <div class="project__ods color-blue" fxFlex="70" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
            <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center"
                data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <p class="title_ods | color-blue bold">Nos apegamos a los ODS</p>
                <p fxFlex="46" fxFlex.md="70" fxFlex.lg="60" fxFlex.xs="80">En Influen<span class="bold900">Ser</span> trabajamos con base en los Objetivos de
                    desarrollo sostenible (ODS) de la ONU.</p>
            </div>
            <div class="mt-30" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center"
                data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <img class="project__roulet" fxFlex="80" fxFlex.xs="90" src="/assets/img/project__roulet.png" alt="InfluenSer">
                <img class="project__roulet--content" fxFlex="30" fxFlex.xl="50" fxFlex.xs="30" fxFlex.md="10" fxFlex.gt="10" fxFlex.sm="20"
                    src="/assets/img/project__ods.png" alt="InfluenSer">
            </div>
        </div>
    </div>
</div>