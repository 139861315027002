import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent  {
  @Output() public sidenavToggle = new EventEmitter();

  public carousel = [
    {
      id: 0,
      img_gd:"../../../../assets/img/banner1_xl.png",
      img_ch:"../../../../assets/img/banner1_lg.png",
      img_sm:"../../../../assets/img/banner1_sm.png",
      img_xs:"../../../../assets/img/banner1_xs.png",
    },
    {
      id: 1,
      img_gd:"../../../../assets/img/banner2_xl.png",
      img_ch:"../../../../assets/img/banner2_lg.png",
      img_sm:"../../../../assets/img/banner2_sm.png",
      img_xs:"../../../../assets/img/banner2_xs.png",
    },
    {
      id: 2,
      img_gd:"../../../../assets/img/banner3_xl.png",
      img_ch:"../../../../assets/img/banner3_lg.png",
      img_sm:"../../../../assets/img/banner3_sm.png",
      img_xs:"../../../../assets/img/banner3_xs.png",
    },
    {
      id: 3,
      img_gd:"../../../../assets/img/banner4_xl.png",
      img_ch:"../../../../assets/img/banner4_lg.png",
      img_sm:"../../../../assets/img/banner4_sm.png",
      img_xs:"../../../../assets/img/banner4_xs.png",
    },
  ];
  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 250,
    point: { visible: true },
    touch: true,
    loop: true,
    load: 2,
    interval: {
      timing: 10000,
      initialDelay: 10000,
    },
    animation: 'lazy',
  };

  constructor(private router: Router) { }

  // ngOnInit(): void {
  //   AOS.init({
  //     duration: 2000,
  //   });
  // }
  openMailito() {
    // window.open("mailto:info@influensernatural.com?Subject=Información%20InfluenSer")
    this.router.navigate(["/services"]);
    this.sidenavToggle.emit();
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }
  

}
