import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  public links: any = [
    "https://www.youtube.com/watch?v=D_UWf5xOI1g",
    "https://www.youtube.com/watch?v=Lcc5m4UZQEI&t=163s",
    "https://www.youtube.com/watch?v=lHKZfo8Sn4E&t=1055s",
    "https://www.ciclo.com.co/",
    "https://grupoexcala.com/",
    "https://corpoayapel.org/"
  ];

  constructor() { }

  ngOnInit(): void {
  }
  goToLink(index) {
    window.open(this.links[index], "_blank");
  }
  openMailito() {
    window.open("mailto:info@influensernatural.com?Subject=Información%20InfluenSer")
  }

}
