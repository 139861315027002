<div class="content_pillars background-green-ligth" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="80" fxFlex.xs="84" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="100" fxHide.xs fxHide.md fxHide.gt fxHide.lg fxHide.xl data-aos="fade-right"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine">
            <p class="content"><span class="color-white title_content bold">¿Qué es ser un</span><br> <span
                    class="color-white  title_content2">Influen</span><span
                    class="color-white  title_content2 bold">Ser?</span></p>
        </div>
        <div fxFlex="48" fxFlex.xs="100" fxFlex.sm="48" fxLayout="row wrap" fxLayoutAlign="center center" data-aos="fade-right"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine">
            <div fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="95" fxFlex.lg="95" fxHide.sm>
                <p class="content"><span class="color-white title_content bold">¿Qué es ser un</span><br> <span
                        class="color-white  title_content2">Influen</span><span
                        class="color-white  title_content2 bold">Ser?</span></p>
            </div>
            <div fxFlex="64" fxFlex.xs="100" fxFlex.sm="95" fxFlex.md="95" fxFlex.lg="95" on-mouseover='over1()'>
                <p class="color-white text_content">1. Es sentir un compromiso importante por sumar mi voluntad en pro
                    de los demás y del planeta. Es pensar
                    que si todos están bien, yo estaré bien.</p>
            </div>
            <div fxFlex="64" fxFlex.xs="100" fxFlex.sm="95" fxFlex.md="95" fxFlex.lg="95" on-mouseover='over2()'>
                <p class="color-white text_content">2. Es ver la necesidad de enfocar mis esfuerzos en mejorar el mundo
                    en el que estamos a través de las
                    acciones en mi día a día.</p>
            </div>
            <div fxFlex="64" fxFlex.xs="100" fxFlex.sm="95" fxFlex.md="95" fxFlex.lg="95" on-mouseover='over3()'>
                <p class="color-white text_content">3. Es promover una cultura de capitalismo consciente, con
                    responsabilidad social e individual, porque
                    estoy convencido de que juntos podemos construir un mundo mejor.</p>
            </div>
            <div fxFlex="60">
                <p class="color-green hashtac">#SerInfluen<span class="bold900">Ser</span></p>
            </div>
        </div>
        <div fxFlex="48" fxFlex.sm="48" class="content_img" *ngIf="flag1" fxLayoutAlign="center center" data-aos="fade-left"
        data-aos-offset="100"
        data-aos-easing="ease-in-sine">
            <img src="../../../../assets/img/whats_being_1_xl.png" fxFlex="100" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
            <img src="../../../../assets/img/whats_being_1_lg.png" fxFlex="100" fxHide.xs fxHide.sm fxHide.xl fxHide.gt>
            <img src="../../../../assets/img/whats_being_1_sm.png" fxFlex="100" fxHide.xs fxHide.md fxHide.gt fxHide.lg
                fxHide.xl>
        </div>
        <div fxFlex="48" fxFlex.sm="48" class="content_img" *ngIf="flag2" fxLayoutAlign="center center" data-aos="fade-left"
        data-aos-offset="100"
        data-aos-easing="ease-in-sine">
            <img src="../../../../assets/img/whats_being_2_xl.png" fxFlex="100" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
            <img src="../../../../assets/img/whats_being_2_lg.png" fxFlex="100" fxHide.xs fxHide.sm fxHide.xl fxHide.gt>
            <img src="../../../../assets/img/whats_being_2_sm.png" fxFlex="100" fxHide.xs fxHide.md fxHide.gt fxHide.lg
                fxHide.xl>
        </div>
        <div fxFlex="48" fxFlex.sm="48" class="content_img" *ngIf="flag3" fxLayoutAlign="center center" data-aos="fade-left"
        data-aos-offset="100"
        data-aos-easing="ease-in-sine">
            <img src="../../../../assets/img/whats_being_3_xl.png" fxFlex="100" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
            <img src="../../../../assets/img/whats_being_3_lg.png" fxFlex="100" fxHide.xs fxHide.sm fxHide.xl fxHide.gt>
            <img src="../../../../assets/img/whats_being_3_sm.png" fxFlex="100" fxHide.xs fxHide.md fxHide.gt fxHide.lg
                fxHide.xl>
        </div>
    </div>
</div>