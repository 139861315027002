import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-pillars',
  templateUrl: './pillars.component.html',
  styleUrls: ['./pillars.component.css']
})
export class PillarsComponent implements OnInit {
  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
  public carouselConfig: NguCarouselConfig;

  @Output() public sidenavToggle = new EventEmitter();

  public generation: any = [
    {
      id: 0,
      img:"../../../../assets/img/every_action_counts_xsH.png",
      imgsm:"../../../../assets/img/every_action_counts_sm.png",
      title:"Toda acción cuenta",
      text:"Las decisiones que tomamos no solo nos afectan a nosotros, sino que tienen un impacto en los demás y en el planeta."
    },
    {
      id: 1,
      img:"../../../../assets/img/shared_value_xsH.png",
      imgsm:"../../../../assets/img/shared_value_sm.png",
      title:"Valor compartido",
      text:"Al generar valor compartido generamos valor para nosotros mismos."
    },
    {
      id: 2,
      img:"../../../../assets/img/continuous_improvement_xsH.png",
      imgsm:"../../../../assets/img/continuous_improvement_sm.png",
      title:"Desarrollo de Estrategias",
      text:"Cada día podemos generar un mejor impacto."
    }
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.carouselConfig = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      load: 1,
      interval: { timing: 4000, initialDelay: 1000 },
      loop: true,
      touch: true,
      velocity: 0.2,
      point: {
        visible: true,
        hideOnSingleSlide: true,
      },
    };
  }
  more(){
    this.router.navigate(["/projects"]);
    this.sidenavToggle.emit();
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

}
