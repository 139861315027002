import { Component } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Influenser-Natural';
  
  ngOnInit(): void {
    AOS.init();
    //this.detectarInfo();
    // console.log('v.1.0.0')// 18/05/22
    console.log('v.1.0.0')// 20/05/22
  }
}
