<div class="background-green-ligth weare__container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="weare__banner color-white" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <p class="fw-600" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">#Somos<span
                class="color-green">Influen<span class="fw-900">Ser</span></span></p>
    </div>
    <div class="content_weare_whatweare color-blue" fxFlex="70" fxFlex.md="85" fxFlex.xs="90" fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="weare__whatweare" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start center"
                data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <p fxFlex="100">
                    Ser <span class="fw-600">Influen<span class="fw-900">Ser</span></span> es ser parte un movimiento
                    global de transformación sociocultural.
                </p>
                <p fxFlex="100">
                    Creemos que como seres humanos tenemos una responsabilidad no solo con nosotros mismos sino con las
                    demás personas y con el entorno.
                </p>
                <p fxFlex="100">
                    Creemos que tenemos la capacidad de concientizar y transformar a los demás.
                </p>
                <p fxFlex="100">
                    Creemos que el cambio empieza con nosotros mismos.
                </p>
            </div>
            <div fxFlex="40" fxFlex.md="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="start center" fxLayoutAlign.sm="center center" data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <img fxFlex="100" fxFlex.sm="80" src="/assets/img/somos__img.png" alt="influenSer"
                    fxLayoutAlign="center center">
            </div>
        </div>
        <div class="content_weare2" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex="40" fxFlex.md="45" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="start center" fxHide.xs fxHide.sm data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <img fxFlex="100" src="/assets/img/somos__quehacemos.png" alt="img" fxLayoutAlign="center center">
            </div>
            <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start center"
                fxLayoutAlign.xs="center center" data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <span class="weare__title weare__whatweare" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">¿Qué
                    hacemos?</span>
                <p class="weare__whatweare">
                    Somos una organización que basada en retos globales de sostenibilidad* <b>identifica, cocrea y
                        acciona estrategias</b>
                    que premian las buenas acciones, contribuyendo a la suma de responsabilidades individuales y
                    fortaleciendo <b>el desarrollo sostenible local.</b>
                </p>
                <p class="weare__note weare__note2">
                    *Generación de valor en el tiempo a través del balance entre el crecimiento económico el desarrollo
                    social y el cuidado del medio ambiente.
                </p>
                <div class="weare__list weare__whatweare  color-blue-green" fxFlex="100" fxLayout.xs="column"
                    fxLayoutAlign="space-between center">
                    <img fxFlex="30" fxFlex.xs="20" src="/assets/img/somos__circle.svg" alt="InfluenSer">
                    <div class="weare__arrow" fxFlex="30" fxFlex.xs="20" fxLayoutAlign="center center">
                        <img fxFlex="100" fxHide.xs src="/assets/img/somos__arrow.png" alt="InfluenSer">
                        <img fxFlex="100" fxHide.gt-sm fxHide.sm src="/assets/img/somos__arrowMovil.svg"
                            alt="InfluenSer">
                        <span class="weare__arrow--text">Escalar, replicar y digitalizar</span>
                    </div>
                    <img fxFlex="25" fxFlex.xs="20" src="/assets/img/somos__influenserlogo.svg" alt="InfluenSer">
                </div>
                <div class="weare__list color-blue-green" fxFlex="100" fxFlex.xs="80" fxLayout.xs="row wrap"
                    fxLayoutAlign.xs="start center">
                    <span class="weare__list__title  color-blue fw-600">Beneficios</span>
                    <ul class="weare__whatweare" fxLayout.sm="row wrap">
                        <li class="color-blue" fxFlex.sm="90">Mayor generación de valor para grupos de interés.</li>
                        <li class="color-blue" fxFlex.sm="90">Identificación de ventajas competitivas mediante la sostenibilidad.</li>
                        <li class="color-blue" fxFlex.sm="90">Reconexión con los valores de la organización / comunidad.</li>
                    </ul>
                </div>
            </div>
            <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="start center"
                fxHide.gt-sm>
                <img fxFlex="100" src="/assets/img/somos__quehacemos.png" alt="img" fxLayoutAlign="center center">
            </div>
        </div>
    </div>
    <div class="weare__generation color-white4" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch">
        <span class="weare__generation--title color-green2" fxFlex="100" fxLayout="row wrap"
            fxLayoutAlign="center center">Generación de valor</span>
        <p class="" fxFlex="100" fxFlex.xs="80" fxLayout="row wrap" fxLayoutAlign="center center">
            Abordamos la sostenibilidad desde todos sus ámbitos: <br>
            <span class="color-green" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">social, económico y
                ambiental</span>
        </p>
        <div class="color-white4" fxFlex="80" fxLayout="row wrap" fxLayoutAlign="space-between start" fxHide.xs
            fxHide.sm>
            <div class="weare__generation--card" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="center start" *ngFor="let item of generation">
                <img src="{{item.img}}" alt="InfluenSer">
                <span class="weare__generation--subtitle color-green" fxFlex="100"
                    fxLayoutAlign="center center">{{item.title}}</span>
                <p class="color-white4" fxFlex="100" fxLayoutAlign="center center">
                    {{item.text}}
                </p>
            </div>
        </div>
        <div fxFlex="80" fxFlex.xs="80" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.gt-sm>
            <ngu-carousel class="carrousel" #myCarousel [inputs]="carouselConfig" [dataSource]="generation">
                <ngu-tile *nguCarouselDef="let item" fxLayoutAlign="center center">
                    <div class="weare__generation--card" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                        fxLayoutAlign="center start">
                        <img src="{{item.img}}" alt="InfluenSer">
                        <span class="weare__generation--subtitle color-green" fxFlex="100"
                            fxLayoutAlign="center center">{{item.title}}</span>
                        <p class="color-white4" fxFlex="100" fxLayoutAlign="center center">
                            {{item.text}}
                        </p>
                    </div>
                </ngu-tile>
                <ul class="myPoint" NguCarouselPoint>
                    <li *ngFor="let i of myCarousel.pointNumbers; let j = index"
                        [class.active]="i == myCarousel.activePoint" (click)="myCarousel.moveTo(i)"
                        [style.background]="'url(' + generation[j] + ')'"></li>
                </ul>
            </ngu-carousel>
        </div>
    </div>
    <div class="weare__services color-blue" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center"><b>Servicios</b></span>
        <div class="color-white4" fxFlex="65" fxFlex.md="85" fxFlex.xs="80" fxFlex.sm="60" fxLayout="row wrap"
            fxLayoutAlign="space-around end">
            <div class="weare__services--card" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="center start">
                <img fxFlex="100" src="/assets/img/somos__talleres2.png" alt="InfluenSer">
                <span class="weare__services--subtitle color-white4" fxFlex="100" fxLayoutAlign="center center">Talleres
                    Interactivos</span>
                <div class="weare__services--hover color-white4" fxFlex="70" fxFlex.sm="80" fxLayout="row wrap"
                    fxLayoutAlign="center stretch">
                    <span class="weare__services--subtitleHover" fxFlex="100" fxLayoutAlign="center center"><b>Talleres
                            Interactivos</b></span>
                    <p>Espacios de reflexión y motivación para la transformación desde la ejemplificación.</p>
                    <p fxFlex="100">1) Tradicional</p>
                    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
                        <span fxFlex="100">2) Experiencial</span>
                        <span fxFlex="100">- en la organización</span>
                        <span fxFlex="100">- en campo</span>
                    </div>
                </div>
            </div>
            <div class="weare__services--card" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="center start">
                <img fxFlex="100" src="/assets/img/somos__analisis2.png" alt="InfluenSer">
                <span class="weare__services--subtitle color-white4" fxFlex="100" fxLayoutAlign="center center">Análisis
                    de Retos</span>
                <div class="weare__services--hover color-white4" fxFlex="70" fxLayout="row wrap"
                    fxLayoutAlign="center stretch">
                    <span class="weare__services--subtitleHover" fxFlex="100" fxLayoutAlign="center center"><b>Análisis
                            de Retos</b></span>
                    <p>Profundizar en la situación y mapeo de grupos de interés para redeterminar el/los reto/s que
                        afronta el cliente.</p>
                </div>
            </div>
            <div class="weare__services--card" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="center start">
                <img fxFlex="100" src="/assets/img/somos__desarrollo2.png" alt="InfluenSer">
                <span class="weare__services--subtitle color-white4" fxFlex="100"
                    fxLayoutAlign="center center">Desarrollo de Estrategias</span>
                <div class="weare__services--hover color-white4" fxFlex="70" fxLayout="row wrap"
                    fxLayoutAlign="center stretch">
                    <span class="weare__services--subtitleHover" fxFlex="100"
                        fxLayoutAlign="center center"><b>Desarrollo de Estrategias</b></span>
                    <p>Generar acciones que permitan resultados emergentes que nos lleven a una nueva teoría
                        (compromiso).</p>
                    <p fxFlex="100">1) Piloto</p>
                    <p fxFlex="100">2) Escalable</p>
                    <p fxFlex="100">3) Replicable</p>
                </div>
            </div>
            <span class="weare__services--note color-blue" fxFlex="100" fxLayout="row wrap"
                fxLayoutAlign="center center">Retos que se transforman en suma de responsabilidades</span>
        </div>
        <div fxFlex="100" fxLayoutAlign="center center">
            <button class="weare__services--button" fxFlex="15" fxFlex.md="25" fxFlex.xs="100" fxFlex.sm="30"
                fxLayout="row wrap" fxLayoutAlign="center center" (click)="openMailito()">Saber más</button>
        </div>
    </div>
    <div class="weare__team color-blue" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
        <span class="weare__team--title color-green" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">Team
            Influen<span class="fw-900">Ser</span></span>
        <div class="" fxFlex="70" fxFlex.md="90" fxLayout="row wrap" fxLayoutAlign="space-around center">
            <div class="color-white" fxFlex="26" fxFlex.md="28" fxFlex.xs="100" fxFlex.sm="40" fxLayout="row wrap"
                *ngFor="let person of team" fxHide.sm>
                <div class="weare__team--card" fxFlex="100" fxLayout="row wrap">
                    <img class="weare__team--cardImg" fxFlex="100" src="{{person.img}}" alt="InfluenSer">
                    <img class="weare__team--cardImgH" fxFlex="100" src="{{person.imgH}}" alt="InfluenSer">
                    <div class="weare__team--cardContent" fxFlex="100" fxLayout="row wrap">
                        <span class="weare__team--name" fxFlex="100">{{person.name}}</span>
                        <span class="color-green2" fxFlex="100">{{person.charge}}</span>
                        <span class="" fxFlex="100">{{person.grade}}</span>
                        <span class="" fxFlex="100">{{person.country}}</span>
                        <div fxFlex="100">
                            <img class="weare__team--media" src="/assets/icons/redes_inB.svg" alt="InfluenSer"
                                (click)="goMedia('linkedin',person)"
                                *ngIf="person.name == 'Benito Mendoza' || person.name == 'Esteban Giraldo'">
                            <img class="weare__team--media" src="/assets/icons/redes_instaB.svg" alt="InfluenSer"
                                (click)="goMedia('instagram',person)" *ngIf="person.name != 'Benito Mendoza'">
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.gt-sm fxHide.xs>
                <div class="color-white" fxFlex="26" fxFlex.xs="100" fxFlex.sm="40" fxLayout="row wrap">
                    <div class="weare__team--card" fxFlex="100" fxLayout="row wrap">
                        <img class="weare__team--cardImg" fxFlex="100" src="{{esteban.img}}" alt="InfluenSer">
                        <img class="weare__team--cardImgH" fxFlex="100" src="{{esteban.imgH}}" alt="InfluenSer">
                        <div class="weare__team--cardContent" fxFlex="100" fxLayout="row wrap">
                            <span class="weare__team--name" fxFlex="100">{{esteban.name}}</span>
                            <span class="color-green2" fxFlex="100">{{esteban.charge}}</span>
                            <span class="" fxFlex="100">{{esteban.grade}}</span>
                            <span class="" fxFlex="100">{{esteban.country}}</span>
                            <div fxFlex="100">
                                <img class="weare__team--media" src="/assets/icons/redes_inB.svg" alt="InfluenSer"
                                    (click)="goMedia('linkedin',esteban)">
                                <img class="weare__team--media" src="/assets/icons/redes_instaB.svg" alt="InfluenSer"
                                    (click)="goMedia('instagram',esteban)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="color-white" fxFlex="26" fxFlex.xs="100" fxFlex.sm="40" fxLayout="row wrap"
                *ngFor="let person of teamTablet" fxHide.gt-sm fxHide.xs>
                <div class="weare__team--card" fxFlex="100" fxLayout="row wrap">
                    <img class="weare__team--cardImg" fxFlex="100" src="{{person.img}}" alt="InfluenSer">
                    <img class="weare__team--cardImgH" fxFlex="100" src="{{person.imgH}}" alt="InfluenSer">
                    <div class="weare__team--cardContent" fxFlex="100" fxLayout="row wrap">
                        <span class="weare__team--name" fxFlex="100">{{person.name}}</span>
                        <span class="color-green2" fxFlex="100">{{person.charge}}</span>
                        <span class="" fxFlex="100">{{person.grade}}</span>
                        <span class="" fxFlex="100">{{person.country}}</span>
                        <div fxFlex="100">
                            <img class="weare__team--media" src="/assets/icons/redes_inB.svg" alt="InfluenSer"
                                (click)="goMedia('linkedin',person)"
                                *ngIf="person.name == 'Benito Mendoza' || person.name == 'Esteban Giraldo'">
                            <img class="weare__team--media" src="/assets/icons/redes_instaB.svg" alt="InfluenSer"
                                (click)="goMedia('instagram',person)" *ngIf="person.name != 'Benito Mendoza'">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Instagram -->
    <div class="insta__container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
        <div fxFlex="96" fxLayout="row wrap" fxLayoutAlign="center" class="title-container">
            <div fxFlex="100" class="color-first amigos" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
                <span class="amigos-link--insta color-blue-green" fxFlex="100" fxLayoutAlign="center center"
                    (click)="goInsta()">Síguenos en @influensernatural</span>
                <span fxFlex="100" fxLayoutAlign="center center" (click)="goHashtag()">#Somos<span
                        class="amigos-link color-green">Influen<span class="fw-900">Ser</span></span></span>
            </div>
        </div>
        <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" class="insta__media__container"
            fxLayoutAlign="space-between stretch">
            <div class="instagram" fxFlex.xs="100" fxFlex="20" fxFlex.sm="50" fxLayout="row wrap"
                fxLayoutAlign="center center" *ngFor="let post of instaURLMedia">
                <div fxFlex="100" fxLayout="row wrap">
                    <div fxFlex="100" *ngIf="post.media_type == 'IMAGE' || post.media_type == 'CAROUSEL_ALBUM'"
                        (click)="goPublish(post)">
                        <img fxFlex="100" src="{{post.media_url}}">
                    </div>
                    <div fxFlex="100" *ngIf="post.media_type == 'VIDEO'" (click)="goPublish(post)">
                        <video fxFlex="100" loop muted autoplay oncanplay="this.play()" controls
                            controlslist="nodownload" onloadedmetadata="this.muted = true" playsinline
                            src="{{post.media_url}}">
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin Instagram -->
</div>