import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { url } from '../models/url.model';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private urlLanding = url;
  private urlI='https://graph.instagram.com/'
  constructor(private http: HttpClient) {}
  public sendContacto(data: any): Observable<any> {
    console.log("Info enviada",data)
    return this.http.post(this.urlLanding + 'contact/web/v1/ApiCreCon/', data);
  }
  public obtenerToken():Observable<any> {
    return this.http.get(this.urlLanding + 'landing/tokens/v1/Tok/cru/?id=1');
  }
  public obtenerURL(accessToken):Observable<any>{  
    return this.http.get(this.urlI+'me/media?fields=permalink&access_token=' + accessToken);
  }
  public obtenerURLMedia(id,accessToken):Observable<any>{  
    return this.http.get(this.urlI + id +'?fields=id,media_type,media_url,username,timestamp&access_token=' + accessToken);
  }
}
