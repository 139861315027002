<div class="content_join background-green-ligth" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="75" fxFlex.xs="85" fxFlex.sm="90" fxFlex.md="90" fxLayout="row wrap" fxLayoutAlign="center center"
        fxLayoutAlign.md="center start">
        <div fxFlex="35" fxFlex.sm="70" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center"
            fxLayoutAlign.sm="space-evenly center" fxLayoutAlign.md="center start">
            <div fxFlex="100" fxFlex.xs="60" fxFlex.sm="60" fxLayout="row wrap" fxLayoutAlign="center center">
                <p class=" title_join | color-blue">Súmate al<br> movimiento</p>
            </div>
            <div fxFlex="100" fxFlex.xs="40" fxFlex.sm="40" fxLayout="row wrap" fxLayoutAlign="center center">
                <img src="../../../../assets/icons/icon_flower.svg" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
                <img class="marginT" src="../../../../assets/icons/icon_flower.svg" fxHide.xs fxHide.sm fxHide.lg
                    fxHide.xl>
                <img src="../../../../assets/icons/icon_flower_xs.svg" fxHide.sm fxHide.md fxHide.gt fxHide.lg
                    fxHide.xl>
                <img src="../../../../assets/icons/icon_flower_sm.svg" fxHide.xs fxHide.md fxHide.gt fxHide.lg
                    fxHide.xl>
            </div>

        </div>
        <div fxFlex="65" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <div class="form_content" fxFlex="90" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap">
                <div class="form-contact " fxLayout="column">
                    <div fxFlex="100" fxLayoutAlign="center center">
                        <p class="title_contact | color-blue-green">¿Cuál será tu pequeño gran cambio?</p>
                    </div>
                    <form id="formulario" [formGroup]="form" autocomplete="off" #fo="ngForm" fxFlex="100"
                        fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.sm="center center"
                        fxLayoutAlign.md="center center">
                        <div class="form mr-3" fxFlex="45" fxFlex.xs="100">
                            <span class="" fxLayout="column">
                                <input class="color_text"
                                    [ngClass]="{error: submitted && form.get('full_name').hasError('required')}"
                                    type="text" name="name" id="name" placeholder="Nombre Completo*"
                                    formControlName="full_name">
                            </span>
                            <div *ngIf="submitted && form.get('full_name').hasError('required')">
                                <span class="alertRed">Este campo es obligatorio</span>
                            </div>
                        </div>
                        <div class="form mr-3" fxFlex="45" fxFlex.xs="100">
                            <span class="" fxLayout="column">
                                <input class="color_text"
                                    [ngClass]="{error: submitted && form.get('email').hasError('required'), errorEmail: submitted && form.get('email').hasError('email')}"
                                    type="email" name="email" id="email" placeholder="Correo*" formControlName="email">
                            </span>
                            <div *ngIf="submitted && form.get('email').hasError('required')">
                                <span class="alertRed">Este campo es obligatorio</span>
                            </div>
                            <div *ngIf="submitted && form.get('email').hasError('email')">
                                <span class="alertRed">Ingrese un correo valido</span>
                            </div>
                        </div>

                        <div class="form" fxFlex="45" fxFlex.xs="100">
                            <span class="" fxLayout="column">
                                <input class="color_text"
                                    [ngClass]="{error: submitted && form.get('phone').hasError('required')}" type="tel"
                                    name="phone" id="phone" placeholder="Teléfono*" formControlName="phone"
                                    (keypress)="keyPressNumbers($event)" maxlength="10">
                            </span>
                            <div *ngIf="submitted && form.get('phone').hasError('required')">
                                <span class="alertRed">Este campo es obligatorio</span>
                            </div>
                        </div>
                        <div class="form" fxFlex="45" fxFlex.xs="100">
                            <span class="" fxLayout="column">
                                <input class="color_text"
                                    [ngClass]="{error: submitted && form.get('company').hasError('required')}"
                                    type="text" name="empresa" id="empresa" placeholder="Empresa*"
                                    formControlName="company">
                            </span>
                            <div *ngIf="submitted && form.get('company').hasError('required')">
                                <span class="alertRed">Este campo es obligatorio</span>
                            </div>
                        </div>
                        <div class="form" fxFlex="45" fxFlex.xs="100">
                            <span class="" fxLayout="column">
                                <textarea class="color_text"
                                    [ngClass]="{errorMsg: submitted && form.get('message').hasError('required')}"
                                    name="message" id="message" placeholder="Mensaje*" maxlength="251"
                                    formControlName="message"></textarea>
                            </span>
                            <div *ngIf="submitted && form.get('message').hasError('required')">
                                <span class="alertRed">Este campo es obligatorio</span>
                            </div>
                        </div>
                        <div class="form" fxFlex="45" fxFlex.xs="65" fxLayoutAlign="center center">
                            <button class="button_submit | color-white " type="submit"
                                (click)="submit()">Enviar</button>
                        </div>

                    </form>

                </div>
            </div>
        </div>

    </div>
</div>
<app-loading *ngIf="showLoad"></app-loading>