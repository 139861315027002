<div class="cont background-green-dark2" fxFlex="100" fxLayout="row wrap" fxLayout.xs="column"
  fxLayoutAlign="center center">

  <div class="footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="content_footer" fxFlex="70" fxFlex.xs="90" fxFlex.sm="85" fxFlex.md="90" fxFlex.lg="85"
      fxLayout="row wrap" fxLayout.xs="row wrap" fxLayoutAlign="center center">
      <div class="footer__logo mt-3" fxFlex="20"  fxFlex.xs="100" fxFlex.sm="35" fxFlex.md="20" fxLayout="row wrap"
        fxLayoutAlign="center center" fxLayoutAlign.md="center start" fxLayoutAlign.xs="center center">
        <img fxFlex="40" fxFlex.xs="45" fxFlex.sm="70" fxFlex.md="60" routerLink="/home" (click)="scrollTop()" class="logo"
          src="../../../assets/icons/logo_influenser_footer.svg" alt="InfluenSer">
      </div>
      <div fxFlex="80" fxFlex.xs="90" fxFlex.sm="65" fxFlex.md="80" fxLayout="row wrap" fxLayoutAlign="space-between " fxHide.sm>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="22" fxFlex.lg="15"
          fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout="column">
          <div fxFlex="50" fxFlex.xs="50" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
            fxLayoutAlign.sm="center center">
            <p class="title | color-main" fxLayoutAlign.sm="start center" fxLayoutAlign.xs="center center"
              fxFlex.xs="100" fxFlex.sm="100">Enlaces Rápidos</p>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
              <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/services" [routerLinkActive]="['active_link']" (click)="scrollTop()" fxFlex="100">Somos Influen<span class="bold900">Ser</span></a>
              </div>
              <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/projects" [routerLinkActive]="['active_link']" (click)="scrollTop()" fxFlex="100">Proyectos</a>
              </div>
              <!-- <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/contact" [routerLinkActive]="['active_link']" (click)="scrollTop()" fxFlex="100">Contacto</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="25"
          fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout="column" fxHide.sm>
          <div fxFlex="100" fxLayout.xs="row wrap" fxLayoutAlign.xs="center center">
            <p class="title | color-main">Contacto</p>
            <div class="redes" fxFlex.xs="100" fxLayout="column" fxLayoutAlign.xs="center center"
              fxLayoutAlign.sm="center center">
              <a class="redes" href="https://wa.link/61ud9q" target="_blank" fxFlex="100">
                (+57) 32 1 646 3897
              </a>
            </div>
            <div class="redes" fxFlex.xs="100" fxLayout="column" fxLayoutAlign.xs="center center"
              fxLayoutAlign.sm="center center">
              <a class="redes" href="mailto:info@influensernatural.com.org?Subject=Contacto%20InfluenSer"
                target="_blank" fxFlex="100">
                info@influensernatural.com
              </a>
            </div>
            <div class="redes" fxFlex.xs="100" fxLayout="column" fxLayoutAlign.xs="center center"
              fxLayoutAlign.sm="center center">
              <a fxFlex="100">
                Medellin Colombia
              </a>
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="25"
          fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout="column">
          <div fxFlex="100">
            <p class="title | color-main" fxLayoutAlign.xs="center center">Información</p>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">
              <a class="redes" routerLink="/home" target="_blank" fxFlex="100">
                Política de Privacidad
              </a>
            </div>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">
              <a class="redes" routerLink="/home" target="_blank" fxFlex="100">
                Términos y condiciones
              </a>
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="25"
          fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout="column" fxHide.gt-sm fxHide.xs>
          <div fxFlex="100" fxLayoutAlign.xs="center center">
            <p class="title | color-main">Contacto</p>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
              <a href="https://api.whatsapp.com/send?phone=+525516526254" target="_blank" fxFlex="100">
                (+57) 32 1 646 3897
              </a>
            </div>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
              <a href="mailto:info@influensernatural.com.org?Subject=Contacto%20InfluenSer" target="_blank"
                fxFlex="100">
                info@influensernatural.com
              </a>
            </div>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
              <a fxFlex="100">
                Medellin Colombia
              </a>
            </div>
          </div>
        </div>
        <div class="footer__social mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="20"
          fxLayoutAlign="start center" fxLayout="row wrap" fxLayoutAlign.xs="center center"
          fxLayoutAlign.sm="start center" fxLayoutAlign.md="start start" fxLayoutAlign.lg="start start"
          fxLayoutAlign.xl="start start">
          <p class="title | color-main" fxFlex="60" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start center">
            Síguenos
          </p>
          <div class="logos" fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
            <div class="container" fxLayoutAlign="center center" id="linkedin">
              <div class="card front">
                <a href="https://co.linkedin.com/company/influenser-natural" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/in_footer.svg"
                    alt="InfluenSer" />
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://co.linkedin.com/company/influenser-natural" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/in_footerH.svg"
                    alt="InfluenSer" />
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center" id="instagram">
              <div class="card front">
                <a href="https://www.instagram.com/influensernatural/" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/insta_footer.svg"
                    alt="InfluenSer" />
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://www.instagram.com/influensernatural/" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/insta_footerH.svg"
                    alt="InfluenSer" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="75" fxFlex.xs="90" fxFlex.sm="65" fxLayout="row wrap" fxLayoutAlign="space-between " fxHide.xs
        fxHide.md fxHide.lg fxHide.xl>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="22" fxFlex.lg="15"
          fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout="column">
          <div fxFlex="50" fxFlex.xs="50" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
            fxLayoutAlign.sm="center center">
            <p class="title | color-main" fxLayoutAlign.sm="start center" fxLayoutAlign.xs="center center"
              fxFlex.xs="100" fxFlex.sm="100">Enlaces Rápidos</p>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
              <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/services" (click)="scrollTop()" fxFlex="100">Somos InfluenSer</a>
              </div>
              <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/projects" (click)="scrollTop()" fxFlex="100">Proyectos</a>
              </div>
              <!-- <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/contact" (click)="scrollTop()" fxFlex="100">Contacto</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="25"
          fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout="column" >
          <div fxFlex="100" fxLayoutAlign.xs="center center">
            <p class="title | color-main">Contacto </p>
            <div  fxFlex.xs="100" class="redes"  fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start center">
              <a href="https://api.whatsapp.com/send?phone=+525516526254" target="_blank" fxFlex="100">
                (+57) 32 1 646 3897
              </a>
            </div>
            <div  fxFlex.xs="100" class="redes"  fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start center">
              <a href="mailto:info@influensernatural.com.org?Subject=Contacto%20InfluenSer" target="_blank"
                fxFlex="100">
                info@influensernatural.com
              </a>
            </div>
            <div  fxFlex.xs="100" class="redes"  fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start center">
              <a fxFlex="100">
                Medellin Colombia
              </a>
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="25"
          fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start center" fxLayout.sm="row wrap" fxLayout="column">
          <div fxFlex="100" >
            <p class="title | color-main" fxLayoutAlign.xs="center center">Información</p>
            <div class="redes"  fxLayoutAlign.xs="center start" fxLayoutAlign.sm="start center">
              <a class="redes" routerLink="/home" target="_blank" fxFlex="100">
                Política de Privacidad
              </a>
            </div>
            <div class="redes"  fxLayoutAlign.xs="center start" fxLayoutAlign.sm="start center">
              <a class="redes" routerLink="/home" target="_blank" fxFlex="100">
                Términos y condiciones
              </a>
            </div>
          </div>
        </div>
        <div class="footer__social mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxFlex.md="20"
          fxLayoutAlign="start center" fxLayout="row wrap" fxLayoutAlign.xs="center center"
          fxLayoutAlign.sm="start center" fxLayoutAlign.md="start start" fxLayoutAlign.lg="start start"
          fxLayoutAlign.xl="start start">
          <p class="title | color-main" fxFlex="60" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start center">
            Síguenos
          </p>
          <div class="logos" fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
            <div class="container" fxLayoutAlign="center center" id="linkedin">
              <div class="card front">
                <a href="https://co.linkedin.com/company/influenser-natural" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/in_footer.svg"
                    alt="InfluenSer" />
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://co.linkedin.com/company/influenser-natural" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/in_footerH.svg"
                    alt="InfluenSer" />
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center" id="instagram">
              <div class="card front">
                <a href="https://www.instagram.com/influensernatural/" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/insta_footer.svg"
                    alt="InfluenSer" />
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://www.instagram.com/influensernatural/" target="_blank">
                  <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icons/insta_footerH.svg"
                    alt="InfluenSer" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="derechos | color-white" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="80" fxFlex.xl="80" fxLayout="row wrap"
      fxLayoutAlign="center center">
      <span class="redes2">Copyright &#169; 2022 | Desarrollado con <img class="heart"
          src="../../../assets/icons/love.svg" alt="Polimentes"> por
      </span>
      <a href="https://www.polimentes.com/" target="_blank"><img class="polimentes ml-5 |"
          src="/assets/img/Polimentes_logo.png" alt="Polimentes">
      </a>
    </div>
    <div fxFlex="100" fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xl="10" fxLayoutAlign="center center">
      <p class="version | color-main" fxFlex="60" fxLayoutAlign="center center" fxLayoutAlign.xs="center center">v1.1.6</p>

    </div>
  </div>
</div>