import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/**Translate */
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
/**Flex */
import { FlexLayoutModule } from '@angular/flex-layout';
/**Angular Material */
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


/**Carrusel */
import { NguCarouselModule } from '@ngu/carousel';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ListmenuComponent } from './components/listmenu/listmenu.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HomeComponent } from './views/home/home.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { ContactComponent } from './views/contact/contact.component';
import { WeAreInfluenserComponent } from './views/we-are-influenser/we-are-influenser.component';
import { ProjectsComponent } from './views/projects/projects.component';
import { BannerComponent } from './views/home/banner/banner.component';
import { InfluenserNaturalComponent } from './views/home/influenser-natural/influenser-natural.component';
import { PillarsComponent } from './views/home/pillars/pillars.component';
import { WhatsBeingInfluenserComponent } from './views/home/whats-being-influenser/whats-being-influenser.component';
import { JoinTheMovementComponent } from './views/home/join-the-movement/join-the-movement.component';
import { CarouselComponent } from './views/home/carousel/carousel.component';
import { GalleryComponent } from './views/home/gallery/gallery.component';
import { SuccessComponent } from './components/message/success/success.component';
import { ErrorComponent } from './components/message/error/error.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ListmenuComponent,
    LoadingComponent,
    HomeComponent,
    NotFoundComponent,
    ContactComponent,
    WeAreInfluenserComponent,
    ProjectsComponent,
    BannerComponent,
    InfluenserNaturalComponent,
    PillarsComponent,
    WhatsBeingInfluenserComponent,
    JoinTheMovementComponent,
    CarouselComponent,
    GalleryComponent,
    SuccessComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NguCarouselModule,
    MatProgressSpinnerModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
