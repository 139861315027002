import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-whats-being-influenser',
  templateUrl: './whats-being-influenser.component.html',
  styleUrls: ['./whats-being-influenser.component.css']
})
export class WhatsBeingInfluenserComponent implements OnInit {
  public flag1:boolean = true;
  public flag2:boolean = false;
  public flag3:boolean = false;

  constructor() { }

  ngOnInit(): void {
    AOS.init({
      duration: 1000
    });
  }
  over1(){
    this.flag1 = true;
    this.flag2 = false;
    this.flag3 = false;
  }
  over2(){
    this.flag1 = false;
    this.flag2 = true;
    this.flag3 = false;
  }
  over3(){
    this.flag1 = false;
    this.flag2 = false;
    this.flag3 = true;
  }

}
