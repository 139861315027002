<mat-nav-list class="nav__container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end stretch">
    <mat-list class="nav__caption" fxLayout="column" fxFlex="100">
        <mat-list-item class="nav__close" fxLayoutAlign="end center">
            <img src="../../../assets/icons/X.svg" alt="X" (click)="onSidenavClose()">
        </mat-list-item>
        <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['home']" (click)="onSidenavClose()">INICIO</span>
        </mat-list-item>
        <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['services']" (click)="onSidenavClose()">SOMOS INFLUENSER</span>
        </mat-list-item>
        <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['projects']" (click)="onSidenavClose()">PROYECTOS</span>
        </mat-list-item>
        <!-- <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['contact']" (click)="onSidenavClose()">CONTACTO</span>
        </mat-list-item> -->
        <mat-list-item fxLayoutAlign="start center" fxFlex="100" >
            <div class="logos" fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center">
                <div class="container" fxLayoutAlign="center center" id="linkedin">
                    <div class="card front">
                      <a href="https://co.linkedin.com/company/influenser-natural"
                        target="_blank">
                        <img class="" fxFlex="100"  src="../../../assets/icons/redes_in.svg"
                          alt="InfluenSer" />
                      </a>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                      <a href="https://co.linkedin.com/company/influenser-natural"
                        target="_blank">
                        <img class="" fxFlex="100"  src="../../../assets/icons/redes_inB.svg"
                          alt="InfluenSer" />
                      </a>
                    </div>
                  </div>
                  <div class="container" fxLayoutAlign="center center" id="instagram">
                    <div class="card front">
                      <a href="https://www.instagram.com/influensernatural/" target="_blank">
                        <img class="" fxFlex="100"  src="../../../assets/icons/redes_insta.svg"
                          alt="InfluenSer" />
                      </a>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                      <a href="https://www.instagram.com/influensernatural/" target="_blank">
                        <img class="" fxFlex="100"  src="../../../assets/icons/redes_instaB.svg"
                          alt="InfluenSer" />
                      </a>
                    </div>
                  </div>
            </div>
        </mat-list-item>
    </mat-list>
</mat-nav-list>