<div class="background-green-ligth contact_container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="contact__banner color-white" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <p fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center"><b>Contáctanos</b></p>
    </div>
    <div class="contact color-blue" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center stretch">
        <div fxFlex="60" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="80" fxLayout="row wrap"
            fxLayoutAlign="space-between start">
            <div class="contact__card" fxFlex="30" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center start"
                fxLayoutAlign.lg="start center" fxLayoutAlign.md="start center">
                <div class="container" fxLayoutAlign="center center">
                    <div class="card front">
                        <img class="" fxFlex="100" fxFlex.xs="85" src="../../../assets/icons/adress_xl.svg"
                            alt="InfluenSer">
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                        <img class="" fxFlex="100" fxFlex.xs="85" src="../../../assets/icons/adress_xlH.svg"
                            alt="InfluenSer">
                    </div>
                </div>
                <span class="contact__title" fxFlex="100" fxLayoutAlign="center center">Dirección</span>
                <span class="" fxFlex="100" fxFlex.xl="58" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayoutAlign.md="center center" fxLayoutAlign.lg="center center">
                    Medellin, Colombia.
                </span>
                <span class="" fxFlex="100" fxFlex.md="88" fxFlex.lg="88" fxFlex.xl="58" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayoutAlign.md="center center" fxLayoutAlign.lg="center center">
                    CDMX, México.
                </span>
                <span class="" fxFlex="100" fxFlex.md="93" fxFlex.lg="93" fxFlex.xl="58" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayoutAlign.md="center center" fxLayoutAlign.lg="center center">
                    Cajamarca, Perú.
                </span>
            </div>
            <div class="contact__card" fxFlex="30" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center start">
                <div class="container mail" fxLayoutAlign="center center" (click)="openMailito()">
                    <div class="card front">
                        <img class="" fxFlex="100" fxFlex.xs="85" src="../../../assets/icons/mail_xl.svg"
                            alt="InfluenSer">
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                        <img class="" fxFlex="100" fxFlex.xs="85" src="../../../assets/icons/mail_xlH.svg"
                            alt="InfluenSer">
                    </div>
                </div>
                <span class="contact__title" fxFlex="100" fxLayoutAlign="center center">Correo</span>
                <p class="" fxFlex="100" fxLayoutAlign="center center">
                    info@influensernatural.com
                </p>
            </div>
            <div class="contact__card" fxFlex="30" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center start">
                <div class="container" fxLayoutAlign="center center">
                    <div class="card front">
                        <img class="" fxFlex="100" fxFlex.xs="85" src="../../../assets/icons/phone_xl.svg"
                            alt="InfluenSer">
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                        <img class="" fxFlex="100" fxFlex.xs="85" src="../../../assets/icons/phone_xlH.svg"
                            alt="InfluenSer">
                    </div>
                </div>
                <span class="contact__title" fxFlex="100" fxLayoutAlign="center center">Teléfono</span>
                <p class="" fxFlex="100" fxLayoutAlign="center center">
                    (+57) 32 1 646 3897
                </p>
            </div>
        </div>
        <div class="contact__formContainer" fxFlex="80" fxFlex.sm="60" fxLayout="row wrap"
            fxLayoutAlign="space-between start">
            <div fxFlex="50" fxFlex.md="47" fxFlex.lg="40"  fxLayout="row wrap" fxLayoutAlign="center start" fxHide.xs fxHide.sm>
                <img fxFlex="100" src="/assets/img/contact__form.png" alt="InfluenSer" fxHide.xs fxHide.sm fxHide.md
                    fxHide.lg>
                <img fxFlex="100" src="/assets/img/contact__form_lg.png" alt="InfluenSer" fxHide.xs fxHide.sm fxHide.xl>
            </div>
            <div fxFlex="50" fxFlex.md="47" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap"
                fxLayoutAlign="center start">
                <span class="contact__formTitle" fxFlex="100" fxLayoutAlign="center start">Se parte del
                    movimiento</span>
                <span class="contact__formHashtag color-green" fxFlex="100"
                    fxLayoutAlign="center start">#SoyInfluen<b>Ser</b></span>
                <form id="formulario" [formGroup]="form" autocomplete="off" #fo="ngForm" fxFlex="100"
                    fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutAlign.sm="center center">
                    <div class="form" fxFlex="85" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="color_text"
                                [ngClass]="{error: submitted && form.get('full_name').hasError('required')}" type="text"
                                name="name" id="name" placeholder="Nombre Completo*" formControlName="full_name">
                        </span>
                        <div *ngIf="submitted && form.get('full_name').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form" fxFlex="85" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="color_text"
                                [ngClass]="{error: submitted && form.get('email').hasError('required'), errorEmail: submitted && form.get('email').hasError('email')}"
                                type="email" name="email" id="email" placeholder="Correo*" formControlName="email">
                        </span>
                        <div *ngIf="submitted && form.get('email').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                        <div *ngIf="submitted && form.get('email').hasError('email')">
                            <span class="alertRed">Ingrese un correo valido</span>
                        </div>
                    </div>

                    <div class="form" fxFlex="85" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="color_text"
                                [ngClass]="{error: submitted && form.get('phone').hasError('required')}" type="tel"
                                name="phone" id="phone" placeholder="Teléfono*" formControlName="phone"
                                (keypress)="keyPressNumbers($event)" maxlength="10">
                        </span>
                        <div *ngIf="submitted && form.get('phone').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form" fxFlex="85" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="color_text"
                                [ngClass]="{error: submitted && form.get('company').hasError('required')}" type="text"
                                name="empresa" id="empresa" placeholder="Empresa*" formControlName="company">
                        </span>
                        <div *ngIf="submitted && form.get('company').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form" fxFlex="85" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <textarea class="color_text"
                                [ngClass]="{errorMsg: submitted && form.get('message').hasError('required')}"
                                name="message" id="message" placeholder="Mensaje*" maxlength="120"
                                formControlName="message"></textarea>
                        </span>
                        <div *ngIf="submitted && form.get('message').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form" fxFlex="85" fxFlex.xs="100" fxLayoutAlign="start center">
                        <button class="button_submit | color-white " type="submit" (click)="submit()">Enviar</button>
                    </div>

                </form>

            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start" fxHide.gt-sm>
                <img class="marginT" fxFlex="100" src="/assets/img/contact__form_sm.png" alt="InfluenSer" fxHide.xs
                    fxHide.md fxHide.lg fxHide.xl>
                <img fxFlex="100" src="/assets/img/contact__form_xs.png" alt="InfluenSer" fxHide.sm fxHide.md fxHide.lg
                    fxHide.xl>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="showLoad"></app-loading>