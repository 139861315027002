<div class="content_influenser background-green-ligth" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="80" fxFlex.sm="89" fxFlex.xs="89" fxLayout="row wrap" fxLayoutAlign="center center" >
        <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="center center" data-aos="fade-right"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine">
            <img fxFlex="100" class="indx_influenser"  src="../../../../assets/img/influenser_xl.png" fxHide.xs fxHide.sm >
        </div>
        <div fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" fxLayoutAlign="center center" data-aos="fade-left"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine">
            <img fxFlex="50" fxFlex.md="60" fxFlex.lg="50" src="../../../../assets/icons/title_influenser.svg" fxHide.xs fxHide.sm >
            <img fxFlex="100" src="../../../../assets/icons/title_influenser_xs.svg" fxFlex="55" fxHide.xs fxHide.md fxHide.gt
            fxHide.lg fxHide.xl>
            <img fxFlex="100" src="../../../../assets/icons/title_influenser_xs.svg" fxFlex="90" fxHide.sm fxHide.md fxHide.gt
            fxHide.lg fxHide.xl>
            <p class="text_influenser color-blue" fxFlex="65" fxFlex.sm="87" fxFlex.xs="100">Porque creemos que tenemos la capacidad de conectar y
                empoderar a las personas para generar un movimiento
                global de <span class="bold">seres</span> comprometidos consigo mismos, la <span class="bold">sociedad</span>  y el <span class="bold">planeta.</span></p>
                <div  fxFlex="65" fxFlex.sm="70" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
                    <p fxFlex="50" fxFlex.xs="100" class="color-blue-green hashtac bold" >#SomosInfluenSer</p>
                    <p fxFlex="50" fxFlex.xs="100" class="color-blue-green hashtac bold" >#Todaaccióncuenta</p>
                </div>
    
            <div class="" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
                <button class="button_more" (click)="services()">Saber más</button>
            </div>
        </div>
        <div fxFlex="40" fxFlex.sm="75" fxFlex.xs="90"  fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="indx_influenser" fxFlex="100" src="../../../../assets/img/influenser_xs.png" fxHide.sm fxHide.md fxHide.gt
            fxHide.lg fxHide.xl>
            <img class="indx_influenser" fxFlex="100" src="../../../../assets/img/influenser_sm.png" fxHide.xs fxHide.md fxHide.gt
            fxHide.lg fxHide.xl>
        </div>

    </div>


</div>