<mat-sidenav-container>
  <mat-sidenav #sidenav position='end' class="menuMovil">
    <app-listmenu (sidenavClose)="sidenav.close()"></app-listmenu>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-navbar (sidenavToggle)="sidenav.toggle()"></app-navbar>
    <main>
      <div class="contenedor">
        <router-outlet></router-outlet>
          <div class="img_whats">
            <a class="redes" href="https://wa.link/61ud9q" target="_blank" fxFlex="100">
              <img fxFlex="100" fxFlex.xs="70" fxFlex.sm="85" fxFlex.md="80" fxFlex.lg="80" fxFlex.xl="85" src="../../../assets/img/whatsapp_icon.png">
            </a>
          </div>
      </div>
      <app-footer></app-footer>

    </main>
  </mat-sidenav-content>
</mat-sidenav-container>